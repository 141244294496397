import React, { useEffect, useContext, useState, useMemo } from 'react';
import { useParams } from 'react-router';
import { isEmpty } from 'lodash';
import { useQuery } from 'react-fetching-library';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import TablePlaceholder from 'components/Shared/TablePlaceholder';
import VirtualizedTable from 'components/Table/VirtualizedTable';
import { routes } from 'config/routes';
import RemoveDialog from 'components/Dialogs/RemoveDialog';
import RoundedButton from 'components/Shared/RoundedButton';
import useFetchList from 'hooks/useFetchList';
import { AppRouteContext } from 'contexts';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import showServerError from 'helpers/showError';
import permissions from 'config/permissions';
import { checkPermission } from 'helpers/checkPermissions';
import useOrderMetadata from 'hooks/useOrderMetadata';
import { getLocalizedDate, getLocalizedLabel } from 'helpers/localize';
import patientsPlaceholderImage from 'assets/placeholders/patients.svg';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import PatientCreateModal from 'components/Patients/PatientCreateModal/PatientCreateModal';
import PatientEditModal from 'components/Patients/PatientEditModal';
import { useDispatch, useSelector } from 'react-redux';
import { NULL_QUERY_DELAY } from '../Shared/constants';
import RefreshTableButton from '../Shared/RefreshTableButton';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../redux_store/reducer/reducers/notificationsReducer';
import { FAKE_PATIENT, GET_PATIENTS_COLUMNS } from './constants';
import getFakeDataList, { FAKE_TITLE_DATA } from '../Shared/FakeDataGenerator';
import { CUSTOM_TAGS_OPTIONS, PATIENT_CUSTOM_TAGS_OPTIONS } from '../Shared/CustomTags';
import { TABLE_KEYS } from '../../redux_store/reducer/reducers/tableColumnsReducer';
import { getTransitionWithVariable } from '../../redux_store/reducer/reducers/i18nReducer';

const FIELDS = [
  'accountCustomTags',
  'firstName',
  'lastName',
  'dateOfBirth',
  'accountBusinessName',
  'mrnNumber',
];

/*
  could be rendered in 2 cases:
  - patients for a current account
  - patients for a special account (accountUuid in URL params)
*/
const PatientsList = () => {
  const {
    en: {
      titles,
      notifications,
      errors,
      btn,
      dialogs,
      placeholders,
    },
  } = useSelector((state) => state.i18n);
  const dispatch = useDispatch();
  const { accountUuid } = useParams();
  const {
    user,
    metadata: {
      currentAccount,
      currentAccountPermissions,
      childAccountPermissions,
    },
  } = useSelector((state) => state.user);
  const [isOpenRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [openPatientCreateModal, togglePatientCreateModal] = useState(false);
  const [filterStyle, setFilterStyle] = useState(false);
  const [openPatientEditModal, togglePatientEditModal] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState({});
  const [removingPatient, setRemovingPatient] = useState({});
  const [orderPatient, setOrderPatient] = useState({});
  const { setAppRoute, breadcrumbs } = useContext(AppRouteContext);
  const isDistributionSponsor = checkPermission(
    currentAccountPermissions,
    permissions.ACCOUNTS_GET,
  );

  const { loading: metadataLoading } = useOrderMetadata(
    orderPatient.uuid,
    orderPatient.accountUUID,
    () => {
      showServerError(dispatch, errors.noProvidersOnThisAccount);
      setOrderPatient({});
    },
    {
      actionTitle: titles.editPatient,
      actionCallback: () => {
        togglePatientEditModal(true);
      },
      callback: () => {
        setOrderPatient({});
      },
    },
  );

  const {
    items,
    totalCount,
    reloadData,
    clearFilter,
    loading: dataLoading,
    ...restFetchProps
  } = useFetchList({
    baseEndpoint: `/accounts/${accountUuid || currentAccount.uuid}/patients`,
    baseParams: { fields: FIELDS.join() },
  });

  const { query: queryRemove, loading: loadingRemove } = useMetadataUpdate(
    useQuery,
    [
      {
        method: 'DELETE',
        endpoint: `/accounts/${removingPatient.accountUUID}/patients/${removingPatient.uuid}`,
      },
      false,
    ],
  );

  const { filter } = restFetchProps;
  const [prevTotalCount, setPrevTotalCount] = useState(totalCount);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  useEffect(() => {
    if (prevTotalCount !== totalCount) {
      setFilterStyle(!isEmpty(filter));
      setPrevTotalCount(totalCount);
    }
  }, [filter, totalCount]);

  const handleRowClick = ({ uuid, accountUUID, firstName, lastName }) => {
    setAppRoute({
      path: routes.patientAssessmentsList.path,
      params: {
        patientUuid: uuid,
        accountUuid: accountUUID,
      },
      title: `${titles.patientDashboard}: ${firstName} ${lastName}`,
    });
  };

  const handleCloseRemoveDialog = () => {
    setOpenRemoveDialog(false);
    setRemovingPatient({});
  };

  const handleRemove = () => {
    const { uuid, firstName, lastName } = removingPatient;
    if (uuid) {
      queryRemove().then(({ error, payload, status }) => {
        const name = `${firstName} ${lastName}`;
        if (!error) {
          setIsSuccess(true);
          setTimeout(() => {
            setIsSuccess(false);
          }, 2000);
          reloadData();
          dispatch(
            enqueueSnackbar({
              message: notifications.patientRemoved,
              options: {
                variant: SNACKBAR_VARIANTS.success,
              },
            }),
          );
        } else {
          setIsFailed(true);
          setTimeout(() => {
            setIsFailed(false);
          }, 2000);
          const options = payload && {
            correlationUUID: payload.correlationUUID,
            userUUID: user.uuid,
          };
          switch (status) {
            case 404: {
              showServerError(dispatch, errors.patientCouldNotFind);
              break;
            }
            case 500: {
              switch (payload.errorCode) {
                case 1: {
                  showServerError(
                    dispatch,
                    getTransitionWithVariable(errors.patientCantBeRemoved, {
                      name: name,
                    }),
                  );
                  break;
                }
                default: {
                  showServerError(dispatch, '', options);
                  break;
                }
              }
              break;
            }
            default: {
              showServerError(dispatch, '', options);
              break;
            }
          }
        }
        setOpenRemoveDialog(false);
        setRemovingPatient({});
      });
    }
  };

  const handleCreateOrderClick = (e, patient) => {
    const hasPermission = checkPermission(
      patient.accountUUID === currentAccount.uuid
        ? currentAccountPermissions
        : childAccountPermissions,
      permissions.ORDER_CREATE,
    );
    if (hasPermission) {
      setSelectedPatient({
        patientUuid: patient.uuid,
        accountUuid: patient.accountUUID,
      });
      setOrderPatient(patient);
    } else {
      dispatch(
        enqueueSnackbar({
          message: notifications.notPermissionForCreatingOrder,
          options: {
            variant: SNACKBAR_VARIANTS.warning,
          },
        }),
      );
    }
  };

  const handleCreateAssessmentClick = (
    e,
    { uuid, accountUUID, firstName, lastName },
  ) => {
    const hasPermission = checkPermission(
      accountUUID === currentAccount.uuid
        ? currentAccountPermissions
        : childAccountPermissions,
      permissions.ORDER_SURVEY_CREATE,
    );
    if (hasPermission) {
      setAppRoute({
        path: routes.patientAssessmentsList.path + '?q=create',
        params: {
          patientUuid: uuid,
          accountUuid: accountUUID,
        },
        title: `${titles.patientDashboard}: ${firstName} ${lastName}`,
      });
    } else {
      dispatch(
        enqueueSnackbar({
          message: notifications.notPermissionForCreatingAssessment,
          options: {
            variant: SNACKBAR_VARIANTS.warning,
          },
        }),
      );
    }
  };

  const handleEditItemClick = (e, { uuid, accountUUID }) => {
    setSelectedPatient({
      patientUuid: uuid,
      accountUuid: accountUUID,
    });
    togglePatientEditModal(true);
  };

  const handleRemoveItemClick = (e, patient) => {
    setOpenRemoveDialog(true);
    setRemovingPatient(patient);
  };

  const patients =
    items &&
    items.map((patient) => {
      const { firstName, lastName } = patient;
      return {
        ...patient,
        dateOfBirth: getLocalizedDate(patient.dateOfBirth, 'UTC'),
        mrnNumber: !patient.mrnNumber ? '-' : patient.mrnNumber,
        accountCustomIdentifier: patient.accountCustomIdentifier
          ? patient.accountCustomIdentifier
          : 'N/A',
        moreTitle: `${firstName} ${lastName}`,
        accountCustomTags: patient.accountCustomTags ? patient.accountCustomTags.map(item => getLocalizedLabel(
          CUSTOM_TAGS_OPTIONS,
          item,
        )) : [],
        customTags: patient.customTags ? patient.customTags.map(item => getLocalizedLabel(
          PATIENT_CUSTOM_TAGS_OPTIONS,
          item,
        )) : [],
      };
    });
  const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
  const titleText = lastBreadcrumb
    ? lastBreadcrumb.title
    : titles.patients;

  const reloadPatientsList = (hasFilter) => {
    if (!hasFilter) {
      clearFilter();
    } else {
      reloadData({ delay: NULL_QUERY_DELAY });
    }
  };
  const FAKE_PATIENT_LIST = getFakeDataList(FAKE_PATIENT);
  const isTableDataLoading = dataLoading || !patients;

  const patientTableColumns = useMemo(
    () => GET_PATIENTS_COLUMNS(
      accountUuid,
      isDistributionSponsor,
    ),
    [accountUuid, isDistributionSponsor],
  );

  return (
    <>
      <PatientCreateModal
        reloadData={reloadData}
        open={openPatientCreateModal}
        accountUuid={accountUuid || currentAccount.uuid}
        handleClose={() => togglePatientCreateModal(false)}
      />
      {!!selectedPatient.patientUuid && (
        <PatientEditModal
          open={openPatientEditModal}
          patientUuid={selectedPatient.patientUuid}
          accountUuid={selectedPatient.accountUuid}
          handleClose={() => togglePatientEditModal(false)}
        />
      )}
      <ContentWrapper
        titleText={titleText}
        count={totalCount || FAKE_TITLE_DATA}
        countFiltered={filterStyle}
        actions={
          /*
            case 1: sponsor and special account
            case 2: not a sponsor
          */
          [
            <RefreshTableButton
              key={'reloadPatientsList'}
              reloadData={reloadPatientsList}
              hasRightPadding={!(isDistributionSponsor && !accountUuid)}
              isLoading={dataLoading}
            />,
            isDistributionSponsor && !accountUuid ? undefined : (
              <RoundedButton
                key='createPatient'
                variant='outlined'
                color='primary'
                size='small'
                disableTextUppercase={true}
                onClick={() => togglePatientCreateModal(true)}
                style={{
                  height: 49,
                }}
              >
                {btn.addPatient}
              </RoundedButton>
            ),
          ]
        }
      >
        <VirtualizedTable
          fakeRows={FAKE_PATIENT_LIST}
          rowCount={totalCount}
          rows={patients}
          columns={patientTableColumns}
          onRowClick={handleRowClick}
          FetchProps={{ ...restFetchProps }}
          onDataLoading={isTableDataLoading}
          currentAccountPermissions={currentAccountPermissions}
          tableKey={isDistributionSponsor ? TABLE_KEYS.patientsDS : TABLE_KEYS.patients}
          hasColumnsSelect={true}
          actionProps={{
            actions: [
              {
                key: '1',
                icon: AddIcon,
                onActionClick: handleCreateOrderClick,
                children: titles.createOrder,
                loading: metadataLoading,
                addDivider: false,
              },
              {
                key: '2',
                icon: AddIcon,
                onActionClick: handleCreateAssessmentClick,
                children: titles.createAssessment,
                loading: metadataLoading,
                addDivider: true,
              },
              {
                key: '3',
                icon: EditIcon,
                onActionClick: handleEditItemClick,
                children: titles.editPatient,
              },
              {
                key: '4',
                icon: RemoveIcon,
                onActionClick: handleRemoveItemClick,
                children: titles.removePatient,
                isRemove: true,
              },
            ],
          }}
          placeholder={
            <TablePlaceholder
              image={patientsPlaceholderImage}
              itemsNotFoundText={getTransitionWithVariable(placeholders.noItemsFound, {
                item: titles.patients,
              })}
              message={getTransitionWithVariable(placeholders.reviseFiltersOrCreateYourFirstItem, {
                item: titles.patients,
              })}
            />
          }
        />

        <RemoveDialog
          open={isOpenRemoveDialog}
          onClose={handleCloseRemoveDialog}
          onSubmit={handleRemove}
          title={getTransitionWithVariable(dialogs.confirmRemove, {
            name: titles.patient,
          })}
          isLoading={loadingRemove}
          isSuccess={isSuccess}
          isFailed={isFailed}
        />
      </ContentWrapper>
    </>
  );
};

export default PatientsList;
