import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RoundedButton from 'components/Shared/RoundedButton';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkPermission } from '../../helpers/checkPermissions';
import permissions from '../../config/permissions';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../redux_store/reducer/reducers/notificationsReducer';
import useOrderMetadata from '../../hooks/useOrderMetadata';
import showServerError from '../../helpers/showError';
import PatientModals from './PatientModals';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme) => ({
  addNewButton: {
    height: '100%',
    marginRight: theme.spacing(2),
  },
}));

const PatientActions = ({ reloadList }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { en: { titles, btn, errors, notifications } } = useSelector((state) => state.i18n);
  const [orderPatientUuid, setOrderPatientUuid] = useState(null); // trigger useOrderMetadata
  const [openResourceModal, toggleResourceModal] = useState(false);
  const [openPatientEditModal, togglePatientEditModal] = useState(false);
  const { search } = useLocation();
  const [openAssessmentModal, toggleAssessmentModal] = useState(search.includes('create'));
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    patientUuid: paramsPatientUuid,
    accountUuid: paramAccountUuid,
  } = useParams();

  const {
    metadata: {
      currentAccount,
      currentAccountPermissions,
      childAccountPermissions,
    },
  } = useSelector((state) => state.user);
  const hasOrderPermission = checkPermission(
    paramAccountUuid === currentAccount.uuid
      ? currentAccountPermissions
      : childAccountPermissions,
    permissions.ORDER_CREATE,
  );
  const hasSurveyPermission = checkPermission(
    paramAccountUuid === currentAccount.uuid
      ? currentAccountPermissions
      : childAccountPermissions,
    permissions.ORDER_SURVEY_CREATE,
  );
  const hasResourcesPermission = checkPermission(
    paramAccountUuid === currentAccount.uuid
      ? currentAccountPermissions
      : childAccountPermissions,
    permissions.GET_RESOURCES,
  );

  const { loading: metadataLoading } = useOrderMetadata(
    orderPatientUuid,
    paramAccountUuid,
    () => {
      showServerError(dispatch, errors.noProvidersOnThisAccount);
      setOrderPatientUuid(null);
    },
    {
      actionTitle: titles.editPatient,
      actionCallback: () => {
        togglePatientEditModal(true);
      },
      callback: () => {
        setOrderPatientUuid(null);
      },
    },
  );

  const handleEditPatient = () => {
    togglePatientEditModal(true);
  };

  const handleResourceCreate = () => {
    toggleResourceModal(true);
  };

  const handleAssessmentCreate = () => {
    const hasPermission = checkPermission(
      paramAccountUuid === currentAccount.uuid
        ? currentAccountPermissions
        : childAccountPermissions,
      permissions.ORDER_SURVEY_CREATE,
    );
    if (hasPermission) {
      toggleAssessmentModal(true);
    } else {
      dispatch(
        enqueueSnackbar({
          message: notifications.notPermissionForCreatingAssessment,
          options: {
            variant: SNACKBAR_VARIANTS.warning,
          },
        }),
      );
    }
  };

  const handleOrderCreate = () => {
    if (hasOrderPermission) {
      setOrderPatientUuid(paramsPatientUuid);
    } else {
      dispatch(
        enqueueSnackbar({
          message: notifications.notPermissionForCreatingOrder,
          options: {
            variant: SNACKBAR_VARIANTS.warning,
          },
        }),
      );
    }
  };

  return (
    <>
      <PatientModals
        openPatientEditModal={openPatientEditModal}
        handleClosePatientEditModal={(shouldReloadList) => {
          if (shouldReloadList) {
            reloadList && reloadList();
          }
          togglePatientEditModal(false);
        }}
        openAssessmentModal={openAssessmentModal}
        handleCloseAssessmentModal={() => {
          toggleAssessmentModal(false);
          if (searchParams.has('q')) {
            searchParams.delete('q')
            const newParams: {[key: string]: string} = {};
            searchParams.forEach((value: string, key: string) => {
              newParams[key] = value;
            });
            setSearchParams(newParams);
          }
        }}
        openResourceModal={openResourceModal}
        handleCloseResourceModal={() => toggleResourceModal(false)}
        reloadData={reloadList ? () => reloadList(true) : () => {
        }}
      />
      {
        hasSurveyPermission &&
        <RoundedButton
          variant='outlined'
          color='primary'
          size='small'
          onClick={handleAssessmentCreate}
          className={classes.addNewButton}
        >
          {btn.createAssessment}
        </RoundedButton>
      }
      {
        hasResourcesPermission && (
          <RoundedButton
            variant='outlined'
            color='primary'
            size='small'
            onClick={handleResourceCreate}
            className={classes.addNewButton}
          >
            {btn.createResource}
          </RoundedButton>
        )
      }
      {
        hasOrderPermission &&
        <RoundedButton
          variant='outlined'
          color='primary'
          size='small'
          onClick={handleOrderCreate}
          className={classes.addNewButton}
          isLoading={metadataLoading}
        >
          {btn.createOrder}
        </RoundedButton>
      }
      <RoundedButton
        variant='outlined'
        color='primary'
        size='small'
        onClick={handleEditPatient}
        className={classes.addNewButton}
      >
        {btn.editPatient}
      </RoundedButton>
    </>
  );
};

PatientActions.propTypes = {
  reloadList: PropTypes.func.isRequired,
};

export default PatientActions;
