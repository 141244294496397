import React, { useContext, useEffect, useMemo, useState } from 'react';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import TablePlaceholder from 'components/Shared/TablePlaceholder';
import VirtualizedTable from 'components/Table/VirtualizedTable';
import useFetchList from 'hooks/useFetchList';
import accountsPlaceholderImage from 'assets/placeholders/accounts.svg';
import { useDispatch, useSelector } from 'react-redux';
import getFakeDataList, {
  FAKE_TITLE_DATA,
} from '../../Shared/FakeDataGenerator';
import { TABLE_KEYS } from '../../../redux_store/reducer/reducers/tableColumnsReducer';
import RefreshTableButton from '../../Shared/RefreshTableButton';
import {
  ALERT_FIELDS,
  ALERT_TAGS_OPTIONS,
  ALERTS_TYPE_SELECTOR_OPTIONS,
  FAKE_ALERTS,
  GET_ALERT_COLUMNS,
  STATUS_OPTIONS,
} from '../constants';
import { getTransitionWithVariable } from '../../../redux_store/reducer/reducers/i18nReducer';
import { useParams } from 'react-router-dom';
import { NULL_QUERY_DELAY } from '../../Shared/constants';
import { getLocalizedDate, getLocalizedLabel } from '../../../helpers/localize';
import { CUSTOM_TAGS_OPTIONS } from '../../Shared/CustomTags';
import { CLAIM_ENTITY_TYPE_SELECTOR_OPTIONS } from '../../ClaimsReporting/constants';
import {
  changeAlertAcknowledgeStatusByUuids,
  getNewAlertsNotification,
  sendViewedAlertsUuids,
} from '../../../redux_store/reducer/reducers/alertsReducer/api';
import { REQUEST_STATUS } from '../../../redux_store/constants';
import { checkPermission } from '../../../helpers/checkPermissions';
import permissions from '../../../config/permissions';
import { camelCase } from 'lodash';
import { ASSESSMENT_STATUSES } from '../../Assessments/constants';
import { routes } from '../../../config/routes';
import { AppRouteContext } from '../../../contexts';
import MultiCheckModeButtons from './widgets/MultiChackModeButtons';
import ActionButtons from './widgets/ActionButtons';
import CheckCircle from '@material-ui/icons/CheckCircleOutline';
import { setAlertsPreFilter } from '../../../redux_store/reducer/reducers/alertsReducer/alertsReducer';
import TableChips from '../../Table/TableChip';

let timeoutID;

const AlertList = () => {
  const {
    metadata: {
      currentAccount,
      currentAccountPermissions,
    },
  } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { en: { titles, placeholders } } = useSelector((state) => state.i18n);
  const {
    tablePreFilters,
    statusSendingViewedAlertsUuids,
    statusChangeAcknowledged,
  } = useSelector((state) => state.alerts);
  const { accountUuid: paramAccountUuid, patientUuid: paramsPatientUuid } = useParams();
  const isPatientPage = Boolean(paramsPatientUuid);
  const { setAppRoute } = useContext(AppRouteContext);
  const [modifiedAlerts, setModifiedAlerts] = useState();
  const [selectedItems, updateSelectedItems] = useState([]);
  const [newViewedAlerts, setNewViewedAlerts] = useState([]);
  const [isMultiCheckMode, setIsMultiCheckMode] = useState(false);
  const [isDataUpdatingAfterActions, setIsDataUpdatingAfterActions] = useState(false);
  const [notViewedAlertsUuids, setNotViewedAlertsUuids] = useState([]);
  const canManageAlerts = checkPermission(currentAccountPermissions, permissions.ALERT_ACTION);
  const isDistributionSponsor = checkPermission(currentAccountPermissions, permissions.ACCOUNTS_GET);

  const {
    items,
    totalCount,
    filter,
    onFilter,
    reloadData,
    clearFilter,
    onMultiColumnFilter,
    totalAccountsCount,
    aggregations,
    loading: dataLoading,
    ...restFetchProps
  } = useFetchList({
    baseEndpoint: `/accounts/${paramAccountUuid || currentAccount.uuid}/alerts`,
    baseParams: {
      fields: ALERT_FIELDS.join(),
    },
    baseFilter: tablePreFilters,
  });

  const sendToBackendViewedAlertsUuids = () => {
    const viewedAlerts = newViewedAlerts.filter((uuid) =>
      notViewedAlertsUuids.includes(uuid),
    );
    setNewViewedAlerts([]);
    if (viewedAlerts.length) {
      dispatch(
        sendViewedAlertsUuids({
          accountUuid: paramAccountUuid || currentAccount.uuid,
          viewedAlertsUuids: viewedAlerts,
        }),
      );
    }
  };

  useEffect(() => {
    if (statusSendingViewedAlertsUuids === REQUEST_STATUS.succeeded) {
      setTimeout(() => {
        dispatch(
          getNewAlertsNotification(paramAccountUuid || currentAccount.uuid),
        );
      }, 500);
    }
  }, [statusSendingViewedAlertsUuids]);

  useEffect(() => {
    if (statusChangeAcknowledged === REQUEST_STATUS.loading) {
      setIsDataUpdatingAfterActions(true);
    } else if (statusChangeAcknowledged === REQUEST_STATUS.failed) {
      setIsDataUpdatingAfterActions(false);
    }
  }, [statusChangeAcknowledged]);

  useEffect(() => {
    if (!dataLoading) {
      setIsDataUpdatingAfterActions(false);
    }
  }, [dataLoading]);

  useEffect(() => {
    if (Object.keys(tablePreFilters).length !== Object.keys(filter).length || Object.keys(tablePreFilters).reduce((acc, key) => acc + (!Object.keys(filter).includes(key) || filter[key] !== tablePreFilters[key] ? 1 : 0), 0) !== 0) {
      onMultiColumnFilter(tablePreFilters);
    }
  }, [tablePreFilters]);

  useEffect(() => {
    if (newViewedAlerts.length) {
      clearTimeout(timeoutID);
      timeoutID = setTimeout(() => {
        sendToBackendViewedAlertsUuids();
      }, 1000);
    }
  }, [newViewedAlerts]);

  useEffect(() => {
    if (items) {
      let newAlertsUuids = [];
      const newModifiedAlerts = items.map((_alert) => {
        let alert = { ..._alert };

        if (!alert.viewedBy) {
          newAlertsUuids.push(alert.uuid);
        }
        alert.alertTags = alert.alertTags.map((alertTag) => {
          if (alertTag === 'SUICIDE OF SELF HARM' || alertTag === 'Suicide or Self Harm Risk' || alertTag === 'Suicide of Self Harm') {
            return 'SUICIDE OR SELF HARM';
          }
          return alertTag;
        });

        return {
          ...alert,
          accountCustomTags: alert.accountCustomTags
            ? alert.accountCustomTags.map((item) =>
              getLocalizedLabel(CUSTOM_TAGS_OPTIONS, item),
            )
            : [],
          patientDateOfBirth: getLocalizedDate(alert.patientDateOfBirth, 'UTC'),
          alertTags: alert.alertTags
            ? alert.alertTags.map((item) =>
              getLocalizedLabel(ALERT_TAGS_OPTIONS, item, true),
            )
            : [],
          orderDate: getLocalizedDate(alert.orderDate, 'UTC'),
          appointmentTimestamp: getLocalizedDate(
            alert.appointmentTimestamp,
            'UTC',
          ),
          invitedTimestamp: getLocalizedDate(alert.invitedTimestamp, 'UTC'),
          viewedTimestamp: getLocalizedDate(alert.viewedTimestamp, 'UTC'),
          entityType: alert?.entityType
            ? getLocalizedLabel(
              CLAIM_ENTITY_TYPE_SELECTOR_OPTIONS,
              alert.entityType,
            )
            : '-',
          alertType: alert?.alertType
            ? getLocalizedLabel(ALERTS_TYPE_SELECTOR_OPTIONS, alert.alertType, true)
            : '-',
          acknowledgeStatus: alert?.acknowledgeStatus
            ? getLocalizedLabel(STATUS_OPTIONS, alert.acknowledgeStatus)
            : '-',
          acknowledgedTimestamp: getLocalizedDate(
            alert.acknowledgedTimestamp,
            'UTC',
          ),
          wasViewed: canManageAlerts ? !!alert.viewedBy : true,
          moreTitle: `${alert.patientFirstName} ${alert.patientLastName}`,
        };
      });
      setModifiedAlerts(newModifiedAlerts);
      setNotViewedAlertsUuids(newAlertsUuids);
    }
  }, [items]);
  console.log(canManageAlerts);

  const FAKE_ALERT_LIST = getFakeDataList(FAKE_ALERTS);

  const reloadAlertList = (hasFilter) => {
    if (!hasFilter) {
      clearFilter();
    } else {
      reloadData({ delay: NULL_QUERY_DELAY });
    }
  };

  const redirectToAssessmentReport = ({
                                        accountUUID,
                                        entityUUID,
                                        patientUUID,
                                        entityName,
                                      }) => {
    setAppRoute({
      path: routes.patientAssessmentReport.path,
      params: {
        accountUuid: accountUUID,
        patientUuid: patientUUID,
        assessmentUuid: entityUUID,
        status: camelCase(ASSESSMENT_STATUSES.completed),
      },
      title: `${entityName} ${titles.report}`,
    });
  };

  const columns = useMemo(
    () =>
      GET_ALERT_COLUMNS(isPatientPage, paramAccountUuid, totalAccountsCount),
    [totalAccountsCount, isPatientPage, paramAccountUuid],
  );

  const _onFilter = (dataKey, value, delay) => {
    onFilter(dataKey, value, delay);
    dispatch(setAlertsPreFilter({
      ...filter,
      [dataKey]: value,
    }));
  };

  return (
    <ContentWrapper
      titleText={titles.alerts}
      count={totalCount || FAKE_TITLE_DATA}
      actions={[
        (
          canManageAlerts ?
            <MultiCheckModeButtons
              isMultiCheckModeOn={isMultiCheckMode}
              onSwitchMode={setIsMultiCheckMode}
            /> : <></>
        ),
        <RefreshTableButton
          reloadData={reloadAlertList}
          hasRightPadding
          isLoading={dataLoading}
        />,
      ]}
      additionalNode={
        <>
          <ActionButtons
            isShown={isMultiCheckMode}
            selectedItems={selectedItems}
            reloadData={reloadData}
          />
          <TableChips aggregations={aggregations} />
        </>
      }
    >
      <VirtualizedTable
        tableKey={isDistributionSponsor ? TABLE_KEYS.alertsDS : TABLE_KEYS.alerts}
        hasColumnsSelect={true}
        fakeRows={FAKE_ALERT_LIST}
        rowCount={totalCount || modifiedAlerts?.length}
        rows={modifiedAlerts}
        columns={columns}
        actionProps={canManageAlerts ? {
          actions: [
            {
              key: '1',
              icon: CheckCircle,
              onActionClick: (e, item) => {
                dispatch(
                  changeAlertAcknowledgeStatusByUuids({
                    accountUuid: paramAccountUuid || currentAccount.uuid,
                    alertsUuids: [item.uuid],
                  }),
                );
              },
              children: titles.acknowledge,
            },
          ],
        } : null}
        multiCheck={isMultiCheckMode}
        multiActionMode={true}
        updateSelectedItems={updateSelectedItems}
        FetchProps={{
          ...restFetchProps,
          filter,
          onFilter: _onFilter,
        }}
        hideContextMenu={isMultiCheckMode}
        onRowClick={isMultiCheckMode ? null : redirectToAssessmentReport}
        currentAccountPermissions={currentAccountPermissions}
        onDataLoading={dataLoading || isDataUpdatingAfterActions}
        onRowViewed={canManageAlerts ? (newUuids) => {
          setNewViewedAlerts([...newViewedAlerts, ...newUuids]);
        } : null}
        placeholder={
          <TablePlaceholder
            image={accountsPlaceholderImage}
            itemsNotFoundText={getTransitionWithVariable(
              placeholders.noItemsFound,
              {
                item: titles.alerts,
              },
            )}
            message={getTransitionWithVariable(
              placeholders.reviseFiltersOrCreateYourFirstItem,
              {
                item: titles.alerts,
              },
            )}
          />
        }
      />
    </ContentWrapper>
  );
};

export default AlertList;
