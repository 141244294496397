import React from 'react';
import i18next from 'i18next';

export const NEW_LINE_MARKER = '%%%';

// apiValue could be a string of comma-separated values
export const getLocalizedLabel = (items, apiValue, ignoreCaseAndSpaces) =>
  apiValue
    .split(',')
    .map((value) => {
      const currentItem = items.find((item) => {
        if (ignoreCaseAndSpaces) {
          return item.value.toLowerCase().replaceAll(' ', '') === value.toLowerCase().replaceAll(' ', '')
        } else {
          return item.value === value;
        }
      });
      return currentItem ? currentItem.label : i18next.t('errors:invalidData');
    })
    .join(NEW_LINE_MARKER);

export const getLocalizedDate = (date, timeZone) => {
  if (!date) {
    return '-';
  }
  return new Date(date).toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone, // default is local, also supports "UTC"
  });
};

export const getLocalizedDateIgnoreTime = (date, timeZone) =>
  new Date(date.split('T')[0] + 'T00:00Z').toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone, // default is local, also supports "UTC"
  });


export function addDays(date, days) {
  date.setDate(date.getDate() + days);
  return date;
}

export const getUtcDate = (date) => {
  if (!date) return null;

  const dateArr = date.split('/');
  if (dateArr.length < 3) {
    return null;
  }

  const utc = new Date(
    `${dateArr[2].toString()}-${dateArr[0].toString()}-${dateArr[1].toString()}`,
  ).toUTCString();

  const parsedDate = new Date(utc).toISOString();

  return `${parsedDate.substr(0, parsedDate.length - 8)}Z`;
};

export const getFormattedDate = (date) => {
  if (!date) {
    return null;
  }
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${month}/${day}/${year}`;
};

export const getFormattedUTCDataFromLocal = (date) => {
  if (!date) {
    return '';
  }
  let date_obj = new Date(date);
  if (new Date().getTimezoneOffset() > 0) {
    date_obj = addDays(date_obj, 1);
  }

  return getFormattedDate(date_obj);
};

export const getLocalizedDateWithTime = (date, timeZone) =>
  new Date(date).toLocaleString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone, // default is local, also supports "UTC"
  });

function getNextDayOfWeek(date, dayOfWeek) {
  // Code to check that date and dayOfWeek are valid left as an exercise ;)
  const resultDate = new Date(date);

  if (resultDate.getDay() === dayOfWeek) {
    if (resultDate.getHours() < 12) {
      resultDate.setDate(resultDate.getDate());
    } else {
      resultDate.setDate(resultDate.getDate() + 7);
    }
  } else {
    resultDate.setDate(
      resultDate.getDate() + ((7 + dayOfWeek - resultDate.getDay()) % 7),
    );
  }

  return resultDate;
}

export const getExpectedDeliveryDate = (date) => {
  const nearestOnFriday = getNextDayOfWeek(date.toLocaleString(), 5);

  const resultDate = new Date(nearestOnFriday.getTime());

  resultDate.setDate(resultDate.getDate() + 11);

  return `${String(resultDate.getMonth() + 1).padStart(2, '0')}/${String(
    resultDate.getDate(),
  ).padStart(2, '0')}/${resultDate.getFullYear()}`;
};

export const getLocalizedAddress = ({
                                      name,
                                      streetAddress,
                                      suite,
                                      city,
                                      state,
                                      zipCode,
                                    }) => {
  const nameLine = name ? `${name}${NEW_LINE_MARKER}` : '';
  const suiteLine = suite ? ` ${suite}` : '';
  return `${nameLine}${streetAddress}${suiteLine}${NEW_LINE_MARKER}${city}, ${state} ${zipCode}`;
};

export const renderLocalizedData = (data) =>
  data ?
    (Array.isArray(data) ? (data.length === 0 ? '-' : data.join(', %%%')) : data)
      .split(NEW_LINE_MARKER)
      .map((dataLine) => <div key={dataLine}>{dataLine}</div>) : '-';
