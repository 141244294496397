import i18next from 'i18next';
import { routes } from '../../config/routes';

const NAV_ITEMS = [
  {
    id: 1,
    route: routes.dashboard,
  },
  {
    id: 2,
    route: routes.accountsList,
  },
  {
    id: 3,
    route: routes.patientsList,
  },
  {
    id: 4,
    route: routes.ordersList,
  },
  {
    id: 5,
    route: routes.assessmentsList,
  },
  {
    id: 6,
    route: routes.allergiEndResources,
  },
  {
    id: 7,
    route: routes.resourcesList,
  },
];

export default NAV_ITEMS;

export const NAV_ADMIN_ITEMS = [
  {
    id: 100,
    route: routes.claimsReportingList,
    children: [
      {
        id: 102,
        route: routes.reportablesList,
      },
      {
        id: 103,
        route: routes.claimReportList,
      },
    ]
  },
];
