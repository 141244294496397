import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../../../client';

export const getNewAlertsNotification = createAsyncThunk(
  'alerts/getNewAlertsNotification',
  async (accountUuid) => {
    return await client.get(
      `/accounts/${accountUuid}/alerts-aggregate`,
    );
  },
);

export const sendViewedAlertsUuids = createAsyncThunk(
  'alerts/sendViewedAlertsUuids',
  async ({ accountUuid, viewedAlertsUuids }) => {
    return await client.post(
      `/accounts/${accountUuid}/alerts-actions`,
      {
        'action': 'view',
        'alertsUUIDs': viewedAlertsUuids,
      },
    );
  },
);

export const changeAlertAcknowledgeStatusByUuids = createAsyncThunk(
  'alerts/changeAlertAcknowledgeStatusByUuids',
  async ({ accountUuid, alertsUuids }) => {

    return await client.post(
      `/accounts/${accountUuid}/alerts-actions`,
      {
        'action': 'acknowledge',
        'alertsUUIDs': alertsUuids,
      },
    );
  },
);
