import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { SortDirection } from 'react-virtualized';
import { get, isEmpty } from 'lodash';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import useFetchList from 'hooks/useFetchList';
import {
  ASSESSMENT_STATUSES,
  ASSESSMENT_STATUSES_OPTIONS,
  ASSESSMENTS_FOR_PATIENT_FIELDS,
  FIELDS,
} from 'components/Assessments/constants';
import { getLocalizedDate, getLocalizedLabel } from 'helpers/localize';
import { useSelector } from 'react-redux';
import { ActivePatientContext } from '../../../contexts/ActivePatientProvider';
import ConfirmResendingInvitation from './widgets/ConfirmResendingInvitation';
import { NULL_QUERY_DELAY } from '../../Shared/constants';
import PatientAdditionalNode from '../../Patients/PatientAdditionalNode';
import PatientInfoBlock from '../../Patients/PatientInfoBlock';
import EnableClaimModal from '../../ClaimsReporting/widgets/EnableClaimModal';
import { FAKE_TITLE_DATA } from '../../Shared/FakeDataGenerator';
import ShowAssessmentLinkModal from './widgets/ShowAssessmentLinkModal';
import Actions from './widgets/Actions';
import {
  CUSTOM_TAGS_OPTIONS,
  PATIENT_CUSTOM_TAGS_OPTIONS,
  SURVEY_TAGS_OPTIONS,
} from '../../Shared/CustomTags';
import TableChips from '../../Table/TableChip';
import AssessmentsTable from './widgets/AssessmentsTable';

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: 'hidden',
  },
  dot: {
    padding: theme.spacing(0, 0.5),
    color: theme.colors.lightBlue,
  },
  patientInfoBox: {
    display: 'flex',
  },
  flex: {
    display: 'flex',
  },
  refreshIcon: {
    marginLeft: theme.spacing(2),
  },
}));

/*
  could be rendered in 2 cases:
  - all account assessments
  - assessments for a special user (patientUuid in URL params)
*/

const defaultConfirmResendInvitationState = {
  patientUUID: '',
  survey: '',
  accountUUID: '',
};

const AssessmentsList = () => {
  const classes = useStyles();
  const { patientUuid, patientInfo } = useContext(ActivePatientContext);
  const { en: { titles, tables, forms } } = useSelector((state) => state.i18n);
  const { metadata: { currentAccount } } = useSelector((state) => state.user);
  const { patientUuid: paramsPatientUuid, accountUuid: paramAccountUuid } = useParams();
  const [tableFilter, setTableFilter] = useState();
  const [prevTotalCount, setPrevTotalCount] = useState();
  const [filterStyle, setFilterStyle] = useState(false);
  const [modifiedAssessments, setModifiedAssessments] = useState();
  const [enableClaimModalInfo, setEnableClaimModalInfo] = useState(null);
  const [assessmentLinkForShareModal, setAssessmentLinkForShareModal] = useState(null);
  const [openConfirmResentDialog, toggleConfirmResentDialog] = useState(defaultConfirmResendInvitationState);
  const [tableSort, setTableSort] = useState({
    sortBy: 'orderDate',
    sortDirection: SortDirection.DESC,
  });
  const isPatientPage = Boolean(paramsPatientUuid);
  const baseEndpoint = `/accounts/${paramAccountUuid || currentAccount.uuid}`;

  const {
    items,
    totalAccountsCount,
    totalCount,
    completionRate,
    noShowRate,
    performanceRate,
    reloadData,
    clearFilter,
    loading: dataLoading,
    ...restFetchProps
  } = useFetchList({
    baseEndpoint: baseEndpoint + '/surveys',
    baseParams: {
      fields: (isPatientPage ? ASSESSMENTS_FOR_PATIENT_FIELDS : FIELDS).join(),
      q: isPatientPage ? `patientUUID:${paramsPatientUuid}` : undefined,
    },
    baseSort: {
      sortBy: 'orderDate',
      sortDirection: SortDirection.DESC,
    },
  });

  const { filter, sort } = restFetchProps;

  useEffect(() => {
    if (prevTotalCount !== totalCount) {
      setFilterStyle(!isEmpty(filter));
      setPrevTotalCount(totalCount);
    }
  }, [filter, totalCount]);

  useEffect(() => {
    if (sort) {
      setTableSort(sort);
    }
    if (filter) {
      setTableFilter(filter);
    }
  }, [sort, filter]);

  useEffect(() => {
    if (items) {
      setModifiedAssessments(
        items.map((assessment) => ({
          ...assessment,
          patientDateOfBirth: getLocalizedDate(
            assessment.patientDateOfBirth,
            'UTC',
          ),
          accountCustomIdentifier: assessment.accountCustomIdentifier
            ? assessment.accountCustomIdentifier
            : 'N/A',
          invitedTimestamp: assessment.invitedTimestamp
            ? getLocalizedDate(assessment.invitedTimestamp, 'UTC')
            : '-',
          appointmentTimestamp: assessment.appointmentTimestamp
            ? getLocalizedDate(assessment.appointmentTimestamp, 'UTC')
            : '-',
          createdBy: assessment.createdBy === 'manuallyCreated'
            ? tables.manuallyCreated
            : assessment.createdBy === 'integration'
              ? tables.integration
              : assessment.createdBy === 'automated'
                ? tables.automated
                : assessment.createdBy,
          patientMrnNumber: !assessment.patientMrnNumber ? '-' : assessment.patientMrnNumber,
          orderDate: getLocalizedDate(assessment.orderDate, 'UTC'),
          status: getLocalizedLabel(
            ASSESSMENT_STATUSES_OPTIONS,
            assessment.status,
          ),
          canRevertNoShowStatus: assessment.status === ASSESSMENT_STATUSES.noShow,
          moreTitle: isPatientPage
            ? `${assessment.surveyName}`
            : `${assessment.patientFirstName} ${assessment.patientLastName}`,
          invitationType: assessment.invitationType,
          claimable: assessment.claimExists === false,
          accountCustomTags: assessment.accountCustomTags ? assessment.accountCustomTags.map(item => getLocalizedLabel(
            CUSTOM_TAGS_OPTIONS,
            item,
          )) : [],
          patientCustomTags: assessment.patientCustomTags ? assessment.patientCustomTags.map(item => getLocalizedLabel(
            PATIENT_CUSTOM_TAGS_OPTIONS,
            item,
          )) : [],
          surveyTags: assessment.surveyTags ? assessment.surveyTags.map(item => getLocalizedLabel(
            SURVEY_TAGS_OPTIONS,
            item,
          )) : [],
        })),
      );
    }
  }, [items]);

  const updateClaimableByUuid = (resourceUuid) => {
    const newModifiedItems = modifiedAssessments.map((item) => {
      return item.uuid === resourceUuid ? { ...item, claimable: false } : item;
    });
    setModifiedAssessments(newModifiedItems);
  };

  const reloadAssessmentsList = (hasFilter) => {
    if (!hasFilter) {
      clearFilter();
    } else {
      reloadData({ delay: NULL_QUERY_DELAY });
    }
  };

  const patientFirstName = get(patientInfo, 'firstName', '');
  const patientLastName = get(patientInfo, 'lastName', '');
  const titleText = isPatientPage
    ? (patientInfo && `${patientLastName}, ${patientFirstName}`) || FAKE_TITLE_DATA
    : titles.assessments;

  const aggregations = useMemo(
    () => {
      const aggregations = {};
      if (completionRate || completionRate === 0) {
        aggregations.completionRate = (completionRate * 100).toFixed(1) + '%';
      }
      if (noShowRate || noShowRate === 0) {
        aggregations.noShowRate = (noShowRate * 100).toFixed(1) + '%';
      }
      if (performanceRate || performanceRate === 0) {
        aggregations.performanceRate = (performanceRate * 100).toFixed(1) + '%';
      }

      return aggregations;
    },
    [completionRate, noShowRate, performanceRate],
  );

  return (
    <>
      {enableClaimModalInfo && (
        <EnableClaimModal
          open
          handleClose={() => setEnableClaimModalInfo(null)}
          codeType='assessment'
          accountUuid={enableClaimModalInfo.accountUUID}
          uuid={enableClaimModalInfo.uuid}
          updateClaimableByUuid={updateClaimableByUuid}
        />
      )}
      {openConfirmResentDialog.survey && (
        <ConfirmResendingInvitation
          source='survey'
          handleClose={() =>
            toggleConfirmResentDialog(defaultConfirmResendInvitationState)
          }
          reloadData={reloadData}
          resendInvitationData={openConfirmResentDialog}
          subtitle={forms.invitationWillBeSentSubtitle}
        />
      )}
      <ShowAssessmentLinkModal
        open={!!assessmentLinkForShareModal}
        handleClose={() => setAssessmentLinkForShareModal(null)}
        assessmentLink={assessmentLinkForShareModal}
      />
      <ContentWrapper
        titleText={titleText}
        className={classes.paper}
        hasTopPaddingForSmallScreen={isPatientPage}
        underTitleText={
          isPatientPage ? (
            <PatientInfoBlock
              isForInitLoading={
                (!items && patientUuid !== paramsPatientUuid) || !patientInfo
              }
            />
          ) : (
            ''
          )
        }
        count={isPatientPage ? null : totalCount || FAKE_TITLE_DATA}
        countFiltered={filterStyle}
        actions={
          <Actions
            reloadList={reloadAssessmentsList}
            dataLoading={dataLoading}
            baseEndpoint={baseEndpoint}
            tableSort={tableSort}
            tableFilter={tableFilter}
            totalAccountsCount={totalAccountsCount}
          />
        }
        additionalNode={
          isPatientPage ? (
            <PatientAdditionalNode
              activeItemNumber={0}
              modifiedData={modifiedAssessments}
              totalCount={totalCount}
              reloadData={reloadAssessmentsList}
              dataLoading={dataLoading}
              historyTitle={tables.assessmentsHistory}
            />
          ) : <TableChips aggregations={aggregations} />
        }
      >
        <AssessmentsTable
          modifiedAssessments={modifiedAssessments}
          dataLoading={dataLoading}
          toggleConfirmResentDialog={toggleConfirmResentDialog}
          reloadData={reloadData}
          totalCount={totalCount}
          FetchProps={restFetchProps}
          totalAccountsCount={totalAccountsCount}
          setEnableClaimModalInfo={setEnableClaimModalInfo}
          setAssessmentLinkForShareModal={setAssessmentLinkForShareModal}
        />
      </ContentWrapper>
    </>
  );
};

export default AssessmentsList;
