import { Box, Grid, Button, makeStyles, Typography, IconButton } from '@material-ui/core';
import React, { useContext } from 'react';
import { ReactComponent as AssessmentIcon } from 'assets/icons/assessment-icon.svg';
import { ReactComponent as OrderIcon } from 'assets/icons/order-big-icon.svg';
import Modal from 'components/Shared/Modal';
import { routes } from 'config/routes';
import { AppRouteContext } from 'contexts';
import { checkPermission } from 'helpers/checkPermissions';
import permissions from 'config/permissions';
import getFullName from 'helpers/user';
import { useDispatch, useSelector } from 'react-redux';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../../../redux_store/reducer/reducers/notificationsReducer';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  goToListLink: {
    color: theme.colors.lightBlue,
    fontWeight: 400,
    fontSize: 15,
    textAlign: 'center',
    cursor: 'pointer',
  },
  modalBtn: {
    height: 128,
    width: 300,
    borderColor: theme.colors.lightBlue,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: 700,
    letterSpacing: 2,
    width: 'max-content',
    color: theme.colors.blue2,
    textTransform: 'uppercase',
    paddingTop: theme.spacing(2),
  },
  iconButton: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    margin: 16,
    width: 'max-content',
    color: theme.colors.blue2,
    fontSize: theme.spacing(3),
    fontWeight: 600,
    borderRadius: 25,
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));

const PatientAddedModal = ({
                             openModal,
                             toggleModal,
                             accountUuid,
                             patient,
                             hasPermissionForSurvey,
                             hasPermissionForOrder,
                           }) => {
  const classes = useStyles();
  const { en: { titles, btn, notifications } } = useSelector((state) => state.i18n);
  const closeAfterCreationModal = () => toggleModal(false);
  const dispatch = useDispatch();
  const { setAppRoute } = useContext(AppRouteContext);
  const { metadata: { currentAccountPermissions } } = useSelector((state) => state.user);
  const isDistributionSponsor = checkPermission(currentAccountPermissions, permissions.ACCOUNTS_GET);
  const patientName = getFullName(patient);

  const onOrderCreate = () => {
    if (
      patient.hasDrugAllergies === null ||
      !patient.hasOwnProperty('hasDrugAllergies') ||
      patient.hasDrugAllergies === ''
    ) {
      dispatch(
        enqueueSnackbar({
          message: notifications.hasDrugAllergies,
          options: {
            variant: SNACKBAR_VARIANTS.warning,
          },
        }),
      );
      return;
    }

    setAppRoute({
      path: routes.patientOrderCreate.path,
      params: {
        patientUuid: patient.uuid,
        accountUuid,
        reorderUuid: 'newOrder',
      },
      title: titles.createOrder,
    });
  };

  const onAssessmentCreate = () => {
    setAppRoute({
      path: routes.patientAssessmentsList.path + '?q=create',
      params: {
        patientUuid: patient.uuid,
        accountUuid,
      },
      title: `${titles.patientDashboard}: ${patientName}`,
    });
  };

  const onListOfPatients = () => {
    closeAfterCreationModal();
    isDistributionSponsor
      ? setAppRoute({
        path: routes.accountPatientsList.path,
        title: routes.accountPatientsList.additional.title,
        params: {
          accountUuid,
        },
        clearBreadcrumbs: true,
      })
      : setAppRoute({
        path: routes.patientsList.path,
        title: routes.patientsList.additional.title,
        clearBreadcrumbs: true,
      });
  };

  return <Modal
    open={openModal}
    disableEscapeKeyDown
    disableBackdropClick
    title={titles.patientAdded}
    handleClose={closeAfterCreationModal}
  >
    <Box pb={2} pt={4} px={4}>
      <IconButton
        aria-label='update'
        className={classes.iconButton}
        onClick={closeAfterCreationModal}
        classes={{ label: classes.label }}
      >
        <CloseIcon />
      </IconButton>
      <Grid container justify='space-evenly'>
        {
          hasPermissionForOrder &&
          <Grid item>
            <Button
              variant='outlined'
              className={classes.modalBtn}
              onClick={onOrderCreate}
            >
              <Box display='flex' alignItems='center' flexDirection='column'>
                <OrderIcon />
                <Typography className={classes.buttonText}>
                  {btn.createOrder}
                </Typography>
              </Box>
            </Button>
          </Grid>
        }
        {
          hasPermissionForSurvey &&
          <Grid item>
            <Button
              variant='outlined'
              className={classes.modalBtn}
              onClick={onAssessmentCreate}
            >
              <Box display='flex' alignItems='center' flexDirection='column'>
                <AssessmentIcon height='40' />
                <Typography className={classes.buttonText}>
                  {btn.addAssessment}
                </Typography>
              </Box>
            </Button>
          </Grid>
        }
      </Grid>
      <Box py={4} onClick={onListOfPatients}>
        <Typography className={classes.goToListLink}>
          {btn.goToListOfPatients}
        </Typography>
      </Box>
    </Box>
  </Modal>;
};

export default PatientAddedModal;
