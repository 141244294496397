/* eslint-disable */
import React, { createRef, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Collapse,
  fade,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-fetching-library';
import { get } from 'lodash';
import cx from 'classnames';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import { ASSESSMENT_STATUSES } from 'components/Assessments/constants';
import smoothscroll from 'smoothscroll-polyfill';
import { getLocalizedDate } from '../../helpers/localize';
import { ActivePatientContext } from '../../contexts/ActivePatientProvider';
import { checkPermission } from '../../helpers/checkPermissions';
import permissions from '../../config/permissions';
import ReportNavigation from './widgets/Navigation';
import ProvidersModal from './widgets/ProvidersModal';
import PatientInfoBlock from '../Patients/PatientInfoBlock';
import EmptyPageLoader from '../Shared/EmptyPageLoader';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../redux_store/reducer/reducers/notificationsReducer';
import { useDispatch, useSelector } from 'react-redux';
import HelpModals from './widgets/HelpModals/HelpModals';
import ContentHeader from './widgets/ContentHeader';
import { getCount } from './helpers';
import ReportContent from './widgets/ReportContent';
import { SURVEYS_HAVE_HELP_INFO } from './constants';

// --------- adding smooth scrolling for click nav link ---------
smoothscroll.polyfill();

const useStyles = makeStyles((theme) => ({
  actions: {
    height: '100%',
  },
  patientName: {
    color: theme.colors.lightBlue,
    textAlign: 'end',
    fontWeight: 500,
  },
  driver: {
    borderTop: '1px solid rgba(0, 0, 0, 0.13)',
  },
  helpIcon: {
    padding: 8,
    border: `1px solid ${theme.palette.error.light}`,
    marginLeft: 5,
    marginBottom: 'auto',
    width: 20,
    height: 20,
    cursor: 'pointer',
    borderColor: theme.colors.lightBlue,
    fontSize: 12,
    color: theme.colors.lightBlue,
  },
  notPrintingArea: {
    '@media print': {
      display: 'none',
    },
    overflowY: 'hidden',
    padding: theme.spacing(1.25, 0, 0, 3),
  },
  underTitleText: {
    fontSize: 15,
    fontStyle: 'italic',
    color: fade(theme.colors.blue2, 0.5),
    marginTop: theme.spacing(1),
  },
  actionsStyle: {
    [theme.breakpoints.between(0, 1315)]: {
      paddingTop: 0,
    },
  },
  headerContent: {
    display: 'flex',
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    zIndex: 3,
    boxShadow: '3px 9px 15px -19px rgba(0, 0, 0, 0.97)',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const ReportView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const topRef = useRef();
  const { t } = useTranslation(['btn', 'errors', 'notifications', 'titles']);
  const [openProvidersModal, toggleProvidersModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingComparing, setIsLoadingComparing] = useState(true);
  const [showHelpPopup, setShowHelpPopup] = useState(false);
  const [blockTitles, setBlockTitles] = useState([]);
  const [blockTitlesComparing, setBlockTitlesComparing] = useState([]);
  const [currentBlockId, setCurrentBlockId] = useState();
  const [refs, setRefs] = useState({});
  const [surveysInfo, setSurveyInfo] = useState();
  const [surveysComparisonInfo, setSurveyComparisonInfo] = useState();
  const [counterOpenTabs, setCounterOpenTabs] = useState(0);
  const [isNavigationPinned, setIsNavigationPinned] = useState(false);
  const [alignment, setAlignment] = React.useState('left');
  const [isOpenDefaultTab, setIsOpenDefaultTab] = useState(true);
  const { metadata: { currentAccountPermissions } } = useSelector((state) => state.user);
  const {
    patientUuid: paramsPatientUuid,
    accountUuid: paramAccountUuid,
    assessmentUuid,
    status: paramStatus,
  } = useParams();
  const [status, setStatus] = useState(paramStatus);
  const {
    patientUuid,
    patientInfo,
  } = useContext(ActivePatientContext);
  const isDistributionSponsor = checkPermission(
    currentAccountPermissions,
    permissions.ACCOUNTS_GET,
  );

  const updateCurrentBlockId = (id, hasDelayed = false) => {
    setTimeout(() => {
      setCurrentBlockId(id);
    }, hasDelayed ? 600 : 0);
  };

  const openAllBlock = () => {
    const newRefs = { ...refs };
    Object.keys(refs).forEach((refKeys) => {
      newRefs[refKeys].open = true;
      setCounterOpenTabs(Object.keys(refs).length);
    });
    isOpenDefaultTabSelected(newRefs);
    setRefs(newRefs);
  };

  const closeAllBlock = () => {
    const newRefs = { ...refs };
    Object.keys(refs).forEach((refKeys) => {
      newRefs[refKeys].open = false;
      setCounterOpenTabs(0);
    });
    isOpenDefaultTabSelected(newRefs);
    setRefs(newRefs);
  };

  const isOpenDefaultTabSelected = (newRefs) => {
    let newIsOpenDefaultTab = !(surveysComparisonInfo || surveysInfo).surveyItems.find(item =>
      item.expandedByDefault !== newRefs[item.title].open,
    );
    if (newIsOpenDefaultTab !== isOpenDefaultTab) {
      setIsOpenDefaultTab(newIsOpenDefaultTab);
    }
  };

  const setOpenBlock = (title) => {
    const newRefs = { ...refs };
    setCounterOpenTabs(
      newRefs[title].open ? counterOpenTabs - 1 : counterOpenTabs + 1,
    );
    newRefs[title].open = !newRefs[title].open;
    isOpenDefaultTabSelected(newRefs);
    setRefs(newRefs);
  };

  const setOpenBlockByDefault = () => {
    let counterExpandedByDefaultTabs = 0;
    const refs = [...(surveysComparisonInfo?.surveyItems ?? []), ...(surveysInfo?.surveyItems ?? [])].reduce(
      (refsObj, { title, expandedByDefault }) => {
        if (expandedByDefault) counterExpandedByDefaultTabs += 1;
        refsObj[title] = {
          open: !!expandedByDefault,
          ref: createRef(),
        };
        return refsObj;
      },
      {},
    );
    setIsOpenDefaultTab(true);
    setCounterOpenTabs(counterExpandedByDefaultTabs);
    setRefs(refs);
  };

  const updateReport = async () => {
    getSurveyInfo();
    setStatus(ASSESSMENT_STATUSES.completed);
    dispatch(
      enqueueSnackbar({
        message: t('notifications:assessmentAccepted'),
        options: {
          variant: SNACKBAR_VARIANTS.success,
        },
      }),
    );
  };

  const { query: querySurveys, loading: loadingSurveys } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint: `/accounts/${paramAccountUuid}/surveys/${assessmentUuid}/report`,
    },
    false,
  ]);

  const {
    query: querySurveysComparison,
    loading: loadingSurveysComparison,
  } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint: `/accounts/${paramAccountUuid}/surveys/${assessmentUuid}/survey-comparison`,
    },
    false,
  ]);


  useEffect(() => {
    if (!isLoading && surveysInfo) {
      let newBlockTitles = [];
      surveysInfo.surveyItems.forEach(({
                                         title,
                                         hideAutoGeneratedReportContent,
                                         reportContent,
                                       }) => {
        if (!(!!hideAutoGeneratedReportContent === true && !!reportContent)) {
          newBlockTitles = [...newBlockTitles, title];
        }
      });
      setBlockTitles(newBlockTitles);
      setCurrentBlockId(newBlockTitles[0]);
    }
  }, [isLoading]);

  const getSurveyInfo = () => {
    querySurveys().then(({ error, payload }) => {
      if (!error) {
        const newSurveyInfo = {
          ...payload,
          termsAcceptedTimestamp: getLocalizedDate(
            payload.termsAcceptedTimestamp,
          ),
        };
        newSurveyInfo.surveyItems = newSurveyInfo.surveyItems.map((props) => {
          const colors = getCount(props.surveyItems);
          return { ...props, colors };
        });
        setSurveyInfo(newSurveyInfo);
        setIsLoading(false);
      }
    });
  };

  const getSurveyComparisonInfo = () => {
    setIsLoadingComparing(true);
    querySurveysComparison().then(({ error, payload }) => {
      if (!error) {
        const newSurveyInfo = {
          ...payload.basicComparison,
          termsAcceptedTimestamp: getLocalizedDate(
            payload.basicComparison.termsAcceptedTimestamp,
          ),
        };
        newSurveyInfo.surveyItems = newSurveyInfo.surveyItems.map((props) => {
          const colors = getCount(props.surveyItems);
          return { ...props, colors };
        });
        if (payload.customComparison && payload.customComparison.length) {
          const customComparisonInfo = {
            'surveyItems': payload.customComparison.map((item) => ({
              ...item,
              'itemType': 'table',
            })),
            'hasValue': true,
            'expandedByDefault': false,
            'uuid': 'customComparison',
            'itemType': 'group',
            'title': 'Custom Comparison',
            'hideAutoGeneratedReportContent': false,
          };
          newSurveyInfo.surveyItems = [...newSurveyInfo.surveyItems, customComparisonInfo];
        }

        setSurveyComparisonInfo(newSurveyInfo);
        let newBlockTitles = [];
        newSurveyInfo.surveyItems.forEach(({
                                             title,
                                             hideAutoGeneratedReportContent,
                                             reportContent,
                                           }) => {
          if (!(!!hideAutoGeneratedReportContent === true && !!reportContent)) {
            newBlockTitles = [...newBlockTitles, title];
          }
        });
        setBlockTitlesComparing(newBlockTitles);
        setAlignment('justify')
      }
      setIsLoadingComparing(false);
    });
  };

  useEffect(() => {
    if (paramsPatientUuid && !loadingSurveys) {
      getSurveyInfo();
      getSurveyComparisonInfo();
    }
  }, [paramsPatientUuid]);

  useEffect(() => {
    if (!isLoadingComparing && !isLoading) {
      setOpenBlockByDefault();
    }
  }, [isLoadingComparing, isLoading]);

  if (
    (patientUuid !== paramsPatientUuid)
    || !patientInfo
    || isLoading
    || loadingSurveysComparison
    || surveysInfo === null
  ) {
    return <>
      <EmptyPageLoader />
      <PatientInfoBlock isReportPage={true} isForInitLoading={true} />
    </>;
  }

  const patientFullName =
    patientInfo &&
    `${get(patientInfo, 'lastName', '')}, ${get(patientInfo, 'firstName', '')}`;

  return (
    <>
      {
        isDistributionSponsor &&
        <ProvidersModal
          updateReport={updateReport}
          openProvidersModal={openProvidersModal}
          toggleProvidersModal={toggleProvidersModal}
        />
      }
      <ContentWrapper
        containerStyle={{
          position: 'relative',
        }}
        className={classes.notPrintingArea}
        underTitleText={`Test Date: ${surveysInfo.termsAcceptedTimestamp}`}
        underTitleTextStyle={classes.underTitleText}
        actionsStyle={cx(classes.actionsStyle, classes.actions)}
        titleText={`${surveysInfo.localizedTitle} Report`}
        additionTitleButton={
          SURVEYS_HAVE_HELP_INFO.includes(surveysInfo.localizedTitle) ?
            <IconButton
              className={classes.helpIcon}
              onClick={() => {
                setShowHelpPopup(true);
              }}
            >
              ?
            </IconButton>
            : null
        }
        actions={
          <>
            <Grid container direction='column' style={{
              paddingRight: 24,
              gap: 10,
              flexWrap: 'nowrap',
            }}>
              <Typography
                noWrap
                className={cx(classes.titleItem, classes.titleText, classes.patientName)}
              >
                {patientFullName}
              </Typography>
              <Grid container>
                <PatientInfoBlock isReportPage={true} />
              </Grid>
            </Grid>
          </>
        }
      >
        <div className={classes.driver} ref={topRef} />

        <div className={classes.headerContent}>
          <ContentHeader
            surveysInfo={surveysInfo}
            patientInfo={patientInfo}
            setOpenBlockByDefault={setOpenBlockByDefault}
            isOpenDefaultTab={isOpenDefaultTab}
            counterOpenTabs={counterOpenTabs}
            toggleProvidersModal={toggleProvidersModal}
            status={status}
            isRightTabDisabled={!surveysComparisonInfo}
            updateReport={updateReport}
            openAllBlock={openAllBlock}
            closeAllBlock={closeAllBlock}
            alignment={alignment}
            setAlignment={setAlignment}
          />
          <ReportNavigation
            topRef={topRef}
            refs={refs}
            setRefs={setRefs}
            currentBlockId={currentBlockId}
            setCurrentBlockId={updateCurrentBlockId}
            blockTitles={alignment === 'left' ? blockTitles : blockTitlesComparing}
            setOpenBlock={setOpenBlock}
            isOpenDefaultTabSelected={isOpenDefaultTabSelected}
            isNavigationPinned={isNavigationPinned}
            setIsNavigationPinned={setIsNavigationPinned}
          />
        </div>

        <ReportContent
          surveysInfo={alignment === 'left' ? surveysInfo : surveysComparisonInfo}
          isComparing={alignment !== 'left'}
          refs={refs}
          updateCurrentBlockId={updateCurrentBlockId}
          currentBlockId={currentBlockId}
          setOpenBlock={setOpenBlock}
          isNavigationPinned={isNavigationPinned}
        />

        <HelpModals
          isLoading={isLoading}
          localizedTitle={surveysInfo.localizedTitle}
          setShowHelpPopup={setShowHelpPopup}
          showHelpPopup={showHelpPopup}
        />
      </ContentWrapper>
    </>
  );
};

export default ReportView;
