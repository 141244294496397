import React, { useEffect, useState } from 'react';
import IdleDialog from 'components/Dialogs/IdleDialog';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, logout } from '../../redux_store/reducer/reducers/userReducer/api';
import {
  changeIsTabActive,
} from '../../redux_store/reducer/reducers/appSettingsReducer';

const LOGOUT_TIMEOUT = 1000 * 60; //ms / s / m / h
let timeoutID;

const removeMSFromTime = (time) => {
  var res = time;
  const res_in_min = res / 60000;
  const t = res_in_min.toString().split('.')[0];
  res = (t * 60000).toString();
  res = res.slice(0, -3) + '000';
  res = parseInt(res);
  return res;
};

export const getDiffWithUTCFromApi = async () => {
  const dateInUTC = await fetch(process.env.REACT_APP_API_URL + '/system/datetime-utc');
  const nowTimeOnDevice = removeMSFromTime(Date.now());

  const data = await dateInUTC.json();
  const date = new Date(data.data);
  const utcTime = date.getTime();
  const diff = utcTime - nowTimeOnDevice;

  localStorage.setItem('UTC_Diff', diff);

  return diff;
};

export const getUTCNow = async () => {
  let diff = parseInt(localStorage.getItem('UTC_Diff'));

  if (diff === null || diff === undefined) {
    diff = await getDiffWithUTCFromApi();
  }

  return removeMSFromTime(Date.now()) + diff;
};

const Idle = () => {
  const { authorizationExpiresTimeout, isTabActive } = useSelector((state) => state.appSettings);
  const { isLogin } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isDialogOpen, setDialogOpen] = useState(false);

  const onStayLogin = () => {
    isLogin && dispatch(getUser());
    setDialogOpen(false);
  };

  useEffect(() => {
    isTabActive && logoutIfNeed();
  }, [isTabActive]);

  const logoutIfNeed = async () => {
    const timeNow = await getUTCNow();
    if (isLogin) {
      if (
        (!authorizationExpiresTimeout
          && localStorage.getItem('authorizationExpiresTimeout')
          && localStorage.getItem('authorizationExpiresTimeout') < timeNow
        ) || (authorizationExpiresTimeout < timeNow)) {
        clearTimeout(timeoutID);
        handleLogout('#auto_logout');
      } else if (!isDialogOpen && (authorizationExpiresTimeout - timeNow <= LOGOUT_TIMEOUT)) {
        clearTimeout(timeoutID);
        setDialogOpen(true);
      } else {
        clearTimeout(timeoutID);
        setTimer(authorizationExpiresTimeout - timeNow)
      }
    }
  };

  const setTimer = (diff) => {
    timeoutID = setTimeout(() => {
      if (localStorage.getItem('isLogin')) {
        setDialogOpen(true);
      }
    }, diff - LOGOUT_TIMEOUT);
  };

  const handleLogout = (text) => {
    const _isLogin = isLogin;
    dispatch(logout());
    if (_isLogin) {
      window.location.href = `/login${text ?? ''}`;
    }
    setDialogOpen(false);
  };

  const checkInitAuthTokenTime = async () => {
    clearTimeout(timeoutID);
    setDialogOpen(false);
    const nowTime = await getUTCNow();
    const diff = authorizationExpiresTimeout - nowTime;
    if (diff > 0) {
      setTimer(diff);
    } else {
      handleLogout('#logout_without_notification');
    }
  };

  useEffect(() => {
    if (authorizationExpiresTimeout) {
      checkInitAuthTokenTime();
    }
  }, [authorizationExpiresTimeout]);

  return <>
    <IdleDialog
      open={isDialogOpen && isLogin}
      logout={handleLogout}
      onClose={onStayLogin}
      onLogout={handleLogout}
      logoutTimeout={LOGOUT_TIMEOUT / 1000}
    />
    <_CheckTabActivity />
  </>;
};

export default Idle;


const _CheckTabActivity = () => {
  const dispatch = useDispatch();

  function handleActivity(forcedFlag) {
    if (typeof forcedFlag === 'boolean') {
      return dispatch(changeIsTabActive(forcedFlag));
    }
    return dispatch(changeIsTabActive(!document.hidden));
  }

  useEffect(() => {
    window.onfocus = function() {
      dispatch(changeIsTabActive(true));
    };

    window.onblur = function() {
      dispatch(changeIsTabActive(false));
    };

    document.addEventListener('visibilitychange', handleActivity);
    return () => {
      document.removeEventListener('visibilitychange', handleActivity);
    };
  }, []);

  return <></>;
};
