import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'contexts';
import CustomMenu, { CUSTOM_MENU_TYPES } from 'components/Shared/CustomMenu';
import CustomMenuItem from 'components/Shared/CustomMenuItem';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { routes } from '../../config/routes';
import { checkPermission } from '../../helpers/checkPermissions';

const MENU_OFFSET_TOP = 12;

const AccountSelector = ({ ...restProps }) => {
  const { t } = useTranslation(['titles']);
  const { changeAccount, changeAccountLoading } = useContext(UserContext);
  const { metadata } = useSelector((state) => state.user);
  const { disabledFeatures } = useSelector((state) => state.appSettings);
  const accountUuid = metadata.currentAccount.uuid;
  const { currentAccountPermissions } = metadata;
  const location = useLocation();

  const handleAccountChange = (e) => {
    if (
      location.pathname === routes.patientsList.path ||
      location.pathname === routes.ordersList.path ||
      location.pathname === routes.assessmentsList.path
    ) {
      changeAccount(e.target.value);
    } else {
      const isAllowedDashboard =
        checkPermission(
          currentAccountPermissions,
          routes.dashboard.additional.permission,
        ) && !disabledFeatures.includes(routes.dashboard.additional.disabledId);

      const isAllowedClaims =
        checkPermission(
          currentAccountPermissions,
          routes.claimsReportingList.additional.permission,
        );

      const isAllowedClaimReport =
        checkPermission(
          currentAccountPermissions,
          routes.claimReportList.additional.permission,
        );

      if (isAllowedDashboard && location.pathname === routes.dashboard.path) {
        changeAccount(e.target.value, {
          path: routes.dashboard.path,
          title: routes.dashboard.additional.title,
          clearBreadcrumbs: true,
        });
      } else if (isAllowedClaims && location.pathname === routes.claimsReportingList.path) {
        changeAccount(e.target.value, {
          path: routes.claimsReportingList.path,
          title: routes.claimsReportingList.additional.title,
          clearBreadcrumbs: true,
        });
      } else if (isAllowedClaimReport && location.pathname === routes.claimReportList.path) {
        changeAccount(e.target.value, {
          path: routes.claimReportList.path,
          title: routes.claimReportList.additional.title,
          clearBreadcrumbs: true,
        });
      } else if (isAllowedClaimReport && location.pathname === routes.alertList.path) {
        changeAccount(e.target.value, {
          path: routes.alertList.path,
          title: routes.alertList.additional.title,
          clearBreadcrumbs: true,
        });
      } else {
        changeAccount(e.target.value, {
          path: routes.patientsList.path,
          title: routes.patientsList.additional.title,
          clearBreadcrumbs: true,
        });
      }
    }
  };

  return (
    <div {...restProps}>
      <CustomMenu
        type={CUSTOM_MENU_TYPES.SELECT}
        title={t('titles:accounts')}
        value={!changeAccountLoading ? accountUuid : '' }
        onChange={handleAccountChange}
        reCalcDimensionsTrigger={accountUuid}
        offsetTop={MENU_OFFSET_TOP}
        disabled={changeAccountLoading}
        isLoading={changeAccountLoading}
      >
        {metadata.accounts.map((account) => (
          <CustomMenuItem key={account.uuid} value={account.uuid}>
            {account.businessName}
          </CustomMenuItem>
        ))}
      </CustomMenu>
    </div>
  );
};

export default AccountSelector;
