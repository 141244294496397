import React, { useMemo } from 'react';
import { Field } from 'formik';
import cx from 'classnames';
import Select from '../../../../../../../../FormikFields/ComplexSelect/Select';
import { makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { getAllIndexes } from '../../../../../../../helpers';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  lightColorBorder: {
    borderColor: alpha('#818FA0', 0.25),
  },
  tableItem: {
    flex: 7,
    minWidth: 140,
    height: 72,
    minHeight: 72,
  },
}));

const styles = {
  activitiesErrorStyle: {
    position: 'absolute',
    bottom: -1,
    marginLeft: 20,
  },
};

const CptCodeComponent = ({
                            rowData: { index },
                            backendErrors,
                            updateFieldValue,
                            cptCodes,
                            validateField,
                          }) => {
  const classes = useStyles();
  const inputProps = { classes: { notchedOutline: classes.lightColorBorder } };

  const onDataChange = async (dataKey, value, field, form) => {
    const prewValue = field.value;
    await form.setFieldValue(`ispConfiguration.services[${index}].cptCode`, value);
    await updateValidation(prewValue, form);
    updateFieldValue(index, 'cptCode', value);
  };

  const updateValidation = async (prewValue, form) => {
    await validateField(`ispConfiguration.services[${index}].cptCode`);
    if (backendErrors && backendErrors[index] && backendErrors[index].cptCode && backendErrors[index].cptCode === 'CPT Code should be unique') {
      const indexesOfSameCptCode = getAllIndexes(form.values.ispConfiguration.services.map(i => i.cptCode), prewValue);
      if (indexesOfSameCptCode.length === 2 && indexesOfSameCptCode.includes(index) || indexesOfSameCptCode.length === 1) {
        for (const i of indexesOfSameCptCode) {
          if (index !== i) {
            await validateField(`ispConfiguration.services[${i}].cptCode`);
          }
        }
      }
    }
  };

  const errors = useMemo(
    () => (backendErrors &&
      backendErrors[index] &&
      backendErrors[index].cptCode
    ),
    [backendErrors],
  );

  return <div className={classes.tableItem}>
    <Field type={'select'} name={`ispConfiguration.services[${index}].cptCode`}>
      {({ field, form }) => {
        return <Select
          name={`ispConfiguration.services[${index}].cptCode`}
          required={true}
          dataKey='code'
          filterValue={field.value}
          disableUnderline
          placeholder={'CPT code'}
          autoComplete="off"
          InputProps={inputProps}
          activitiesErrorStyle={styles.activitiesErrorStyle}
          className={cx(classes.margin0, classes.fullWidth)}
          columnData={{
            isFilterable: true,
            options: (cptCodes || []),
          }}
          onChange={(dataKey, value) => onDataChange(dataKey, value, field, form)}
          error={errors}
        />;
      }}
    </Field>
  </div>;
};

export default CptCodeComponent;
