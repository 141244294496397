import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  DialogContentText,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import RoundedButton from 'components/Shared/RoundedButton';
import { getUTCNow } from '../Shared/Idle';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'space-between',
    padding: theme.spacing(1, 3, 2, 3),
  },
  progress: {
    color: theme.palette.common.white,
  },
}));

let timeoutID;

const IdleDialog = ({
                      open,
                      logout,
                      onClose,
                      onLogout,
                      logoutTimeout,
                      ...rest
                    }) => {
  const { t } = useTranslation(['btn', 'dialogs']);
  const { isTabActive } = useSelector((state) => state.appSettings);
  const classes = useStyles();
  const [counter, setCounter] = useState(logoutTimeout);
  const intervalRef = useRef();

  useEffect(() => {
    if (counter <= 0) {
      onLogout('#auto_logout');
    }
  }, [counter]);

  const checkAuthTime = async () => {
    intervalRef.current = setInterval(async () => {
      setCounter((localStorage.getItem('authorizationExpiresTimeout') - await getUTCNow()) / 1000);
    }, 1000);

    timeoutID = setTimeout(async () => {
      if (localStorage.getItem('authorizationExpiresTimeout') - await getUTCNow() <= 0) {
        onLogout('#auto_logout');
      }
    }, logoutTimeout * 1500);
  };

  useEffect(() => {
    if (isTabActive && open) {
      clearTimeout(timeoutID);
      clearInterval(intervalRef.current);
      checkAuthTime()
    }
  }, [isTabActive]);

  useEffect(() => {
    if (open) {
      checkAuthTime();
    } else {
      clearTimeout(timeoutID);
      clearInterval(intervalRef.current);
      setCounter(logoutTimeout);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => {
      }}
      aria-labelledby='idle-title'
      fullWidth
      maxWidth='xs'
      disableBackdropClick
      disableEscapeKeyDown
      {...rest}
    >
      <DialogTitle id='idle-title'>{t('dialogs:sessionTimeout')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('dialogs:inactivityWarning')}</DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <RoundedButton
          onClick={() => logout('#logout_without_notification')}
          variant='contained'
          color='secondary'
          size='small'
        >
          {t('btn:logOut')}
        </RoundedButton>
        <RoundedButton
          onClick={onClose}
          variant='contained'
          color='primary'
          size='small'
          endIcon={
            <CircularProgress
              variant='static'
              value={Math.round((counter / logoutTimeout) * 100)}
              size={18}
              className={classes.progress}
            />
          }
        >
          {t('btn:stayLoggedIn')}
        </RoundedButton>
      </DialogActions>
    </Dialog>
  );
};

IdleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  logoutTimeout: PropTypes.number.isRequired,
};

export default IdleDialog;
