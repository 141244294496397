import {
  FormHelperText,
  FormLabel,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import CalendarPopover from '../../../../FormikFields/Calendar/CalendarPopover';
import { getFormattedDate } from '../../../../../helpers/localize';

const useStyles = makeStyles((theme) => ({
  datepickerWrapper: {
    padding: '3px 12px',
    borderRadius: 8,
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    height: 40,

    '& .MuiIconButton-root': {
      padding: '0px !important',
    },
  },
  datepickerErrorWrapper: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  datepickerLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '.8rem',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
    backgroundColor: '#FFFFFF',
    position: 'absolute',
    top: -12,
    left: 9,
    padding: 4,
    zIndex: 1,
  },
  datepickerErrorLabel: {
    color: theme.palette.error.main,
  },
  scheduledInputsDate: {
    maxWidth: 180,
    marginRight: 20,
  },
  formError: {
    marginTop: 6,
  },
}));

const DatePicker = ({ name, required = false, backendErrors, setBackendErrors }) => {
  const classes = useStyles();
  const { en: { forms } } = useSelector((state) => state.i18n);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return <div className={classes.scheduledInputsDate}>
    <Field type='data' name={name}>
      {({ field: dateField, form: { errors, setFieldValue, validateField } }) => {
        return (
          <div style={{ position: 'relative' }}>
            <FormLabel
              {...{
                className: cx(classes.datepickerLabel, {
                  [classes.datepickerErrorLabel]: errors[name] || backendErrors[name],
                }),
              }}
            >
              {forms.scheduleDeliverDate}
            </FormLabel>
            <div className={cx(classes.datepickerWrapper, {
              [classes.datepickerErrorWrapper]: errors[name] || backendErrors[name],
            })}>
              <CalendarPopover
                width={100}
                shouldRange={false}
                onChange={(newDate) => {
                  if (!newDate) {
                    setFieldValue(name, '');
                  } else {
                    setFieldValue(
                      name,
                      getFormattedDate(newDate),
                    );

                    if (errors[name]) {
                      setTimeout(() => validateField(name));
                    }
                    if (backendErrors[name]) {
                      setTimeout(() => setBackendErrors({ ...backendErrors, [name]: null }));
                    }
                  }
                }}
                minDate={today}
                required={required}
                error={errors[name] || backendErrors[name]}
                date={
                  !dateField.value
                    ? ''
                    : new Date(dateField.value)
                }
              />
              {(errors[name] || backendErrors[name]) &&
              <FormHelperText error className={classes.formError}>
                {errors[name] || backendErrors[name]}
              </FormHelperText>}
            </div>
          </div>
        );
      }}
    </Field>
  </div>;
};

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

DatePicker.defaultProps = {
  required: false,
};

export default DatePicker;
