const downloadPDF = async (link) => {
  // fake server request, getting the file url as response
  await setTimeout(() => {
    const response = {
      file: process.env.REACT_APP_API_URL + link,
    };
    window.location.href = response.file;
  }, 100);
};

export default downloadPDF;

export const downloadFile = (blob, fileName) => {
  const link = document.createElement('a');
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

