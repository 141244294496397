import React, { useContext } from 'react';
import { AppRouteContext, UserContext } from 'contexts';
import { routes } from 'config/routes';
import Dashboard from 'components/Dashboard/Dashboard';
import Loader from 'components/Shared/Loader';
import { checkPermission } from 'helpers/checkPermissions';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const ProtectedRoute = ({ ...rest }) => {
  const params = useParams();
  const { user, isLogin, metadata } = useSelector((state) => state.user);
  const { setAppRoute, breadcrumbs } = useContext(AppRouteContext);
  const { disabledFeatures } = useSelector((state) => state.appSettings);
  const { changeAccountLoading } = useContext(UserContext);
  const {
    additional: { component: Component, permission },
  } = rest;
  const renderRoute = (props) => {
    // initial app route setup
    if (!breadcrumbs.length && !changeAccountLoading) {
      setAppRoute({
        path: rest.path,
        params,
        title: rest.additional.title,
      });
    }

    if (
      changeAccountLoading ||
      isLogin === undefined ||
      (isLogin && user === null) ||
      (isLogin && user === undefined) ||
      (isLogin && user && metadata === null) ||
      (isLogin && user && metadata === undefined)
    ) {
      return <Loader fullHeight />;
    }
    // {}: user exist
    if (user && metadata) {
      // check route permission
      // if user has not permission - redirect to patientsList
      const { currentAccountPermissions } = metadata;
      const isAllowed =
        checkPermission(currentAccountPermissions, permission) &&
        !disabledFeatures.includes(rest.additional.disabledId);
      if (!isAllowed) {
        const isAllowedDashboard =
          checkPermission(
            currentAccountPermissions,
            routes.dashboard.additional.permission,
          ) &&
          !disabledFeatures.includes(routes.dashboard.additional.disabledId);

        if (isAllowedDashboard) {
          setAppRoute({
            path: routes.dashboard.path,
            title: routes.dashboard.additional.title,
            clearBreadcrumbs: true,
          });
        } else {
          setAppRoute({
            // path: routes.notFoundProtected.path,
            // removeQueryString: true,
            // clearBreadcrumbs: true,
            path: routes.patientsList.path,
            title: routes.patientsList.additional.title,
            clearBreadcrumbs: true,
          });
        }
        return null;
      }
      return (
        <Dashboard isDialogOpen={rest.isDialogOpen}>
          <Component {...props} />
        </Dashboard>
      );
    }
    // null: error occurs
    setAppRoute({
      path: routes.login.path,
    });
    return null;
  };

  return renderRoute(rest);
};

ProtectedRoute.propTypes = {};

export default ProtectedRoute;
