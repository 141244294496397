import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import MenuRow from './../MenuRow';
import StyledLoader from '../Loader';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import NoOptions from '../NoOptions';
import useFetchList from '../../../../../hooks/useFetchList';
import InfiniteScroll from 'react-infinite-scroll-component';
import { uid } from '../../../../FormikFields/ComplexSelect/SimpleSelect';

export const useStyles = makeStyles((theme) => ({
  resultsContainer: {
    overflow: 'auto',
    height: 390,
    width: '100%',

    '& .infinite-scroll-component': {
      overflow: 'hidden !important',

    },
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const AllOptions = ({
                      selectedOptions = [],
                      setSelectedOptions,
                      dataKey,
                      handleClose,
                      open,
                      tableName,
                      searchValue,
                      searchValueAll,
                      showOnlyAvailableOptions,
                      tableFilter,
                    }) => {
  const { accountUuid: paramAccountUuid } = useParams();
  const { metadata: { currentAccount } } = useSelector((state) => state.user);
  const scrolableId = uid();
  const scrolableIdAll = scrolableId + 'All';

  const othersFieldsFilters = Object.keys(tableFilter)
    .filter(key => key !== dataKey)
    .reduce((obj, key) => {
      obj[key] = tableFilter[key];
      return obj;
    }, {});

  const {
    items,
    totalCount,
    loading: dataLoading,
    onLoadMoreRows,
    onMultiColumnFilter,
  } = useFetchList({
    baseEndpoint: `/accounts/${paramAccountUuid || currentAccount.uuid}/lookups/${tableName}`,
    baseParams: { fields: [dataKey] },
  });

  const {
    items: itemsAll,
    totalCount: totalCountAll,
    loading: dataLoadingAll,
    onLoadMoreRows: onLoadMoreRowsAll,
    onMultiColumnFilter: onMultiColumnFilterAll,
  } = useFetchList({
    baseEndpoint: `/accounts/${paramAccountUuid || currentAccount.uuid}/lookups/${tableName}`,
    baseParams: { fields: [dataKey] },
  });

  useEffect(() => {
    onMultiColumnFilter({
      ...othersFieldsFilters,
      [dataKey]: searchValue,
    }, true);
  }, [searchValue]);

  useEffect(() => {
    onMultiColumnFilterAll({
      [dataKey]: searchValueAll,
    }, true);
  }, [searchValueAll]);

  useEffect(() => {
    if (open) {
      if (showOnlyAvailableOptions) {
        onMultiColumnFilter({
          ...othersFieldsFilters,
          [dataKey]: '',
        }, false);
      } else {
        onMultiColumnFilterAll({
          [dataKey]: '',
        }, false);
      }
    }
  }, [open]);

  const onOptionCheckboxClick = (option, checked) => {
    if (checked) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      setSelectedOptions(selectedOptions.filter(item => item !== option));
    }
  };

  return <div>
    <div style={{
      width: '100%',
      height: '100%',
      display: showOnlyAvailableOptions ? 'flex' : 'none',
    }}>
      <_Content
        items={items}
        totalCount={totalCount}
        dataLoading={dataLoading}
        onLoadMoreRows={onLoadMoreRows}
        scrolableId={scrolableId}
        selectedOptions={selectedOptions}
        onOptionCheckboxClick={onOptionCheckboxClick}
        handleClose={handleClose}
        othersFieldsFilters={{}}
      />
    </div>
    <div style={{
      width: '100%',
      height: '100%',
      display: !showOnlyAvailableOptions ? 'flex' : 'none',
    }}>
      <_Content
        items={itemsAll}
        totalCount={totalCountAll}
        dataLoading={dataLoadingAll}
        onLoadMoreRows={onLoadMoreRowsAll}
        scrolableId={scrolableIdAll}
        selectedOptions={selectedOptions}
        onOptionCheckboxClick={onOptionCheckboxClick}
        handleClose={handleClose}
        othersFieldsFilters={othersFieldsFilters}
      />
    </div>
  </div>;
};

const _Content = ({
                    items,
                    totalCount,
                    dataLoading,
                    onLoadMoreRows,
                    selectedOptions,
                    handleClose,
                    scrolableId,
                    onOptionCheckboxClick,
                  }) => {
  const classes = useStyles();
  const { en: { titles } } = useSelector((state) => state.i18n);
  const hasOptionsFromApi = !!(items && items?.length);

  const [isLoadMore, setIsLoadMore] = useState(false);

  useEffect(() => {
    if (!dataLoading) {
      setIsLoadMore(false);
    }
  }, [dataLoading]);

  return <div id={scrolableId} className={classes.resultsContainer}>
    <InfiniteScroll
      dataLength={totalCount || 0}
      next={() => {
      }}
      initialScrollY={10}
      scrollableTarget={scrolableId}
      onScroll={(e) => {
        if (e.target.scrollTop + 500 >= e.target.scrollHeight) {
          if (!dataLoading && onLoadMoreRows && ((items || []).length) < totalCount) {
            setIsLoadMore(true);
            onLoadMoreRows((items || []).length);
          }
        }
      }}
      hasMore={true}
      loader={isLoadMore ? <h4>Loading...</h4> : <></>}
    >
      {
        (!dataLoading || isLoadMore) && items && items.map((option) => (
          <MenuRow
            option={option}
            selected={selectedOptions}
            onItemClick={onOptionCheckboxClick}
            applyCurrentFilter={(e) => handleClose(e, option)}
          />
        ))
      }
    </InfiniteScroll>
    {dataLoading && !isLoadMore && <StyledLoader />}
    {!dataLoading && !hasOptionsFromApi && <NoOptions title={titles.nothingFound} />}
  </div>;
};

export default AllOptions;


