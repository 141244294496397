import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../../client';
import { logout } from './userReducer/api';
import { REQUEST_STATUS } from '../../constants';

const checkSideMenu = () => {
  return localStorage.getItem('IsSideMenuPinned');
};

const setIsSideMenuPinned = (val) => {
  localStorage.setItem('IsSideMenuPinned', val);
};

const checkAuthorizationExpiresTimeoutLocally = () => {
  return localStorage.getItem('authorizationExpiresTimeout');
};

const setAuthorizationExpiresTimeoutLocally = (val) => {
  localStorage.setItem('authorizationExpiresTimeout', val);
};

const clearAuthorizationExpiresTimeoutLocally = () => {
  localStorage.removeItem('authorizationExpiresTimeout');
};

export const fetchDisabledFeatures = createAsyncThunk(
  'disabledfeatures/fetchDisabledfeatures',
  async () => {
    const res = await client.get(`/system/disabledfeatures`);
    return res;
  },
);

export const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState: {
    isSideMenuPinned: true,
    isTabActive: true,
    disabledFeatures: [],
    status: REQUEST_STATUS.idle,
    error: null,

    // auto-logout time
    authorizationExpiresTimeout: null,
  },
  reducers: {
    getAndSetIsSideMenuPinned: (state) => {
      if (checkSideMenu() === 'false') {
        state.isSideMenuPinned = false;
      }
    },
    checkAuthorizationExpiresTimeout: (state) => {
      state.authorizationExpiresTimeout = checkAuthorizationExpiresTimeoutLocally();
    },
    changeIsTabActive: (state, action) => {
      state.isTabActive = action.payload;
    },
    setAuthorizationExpiresTimeout: (state, action) => {
      if (process.env.REACT_APP_ENV !== 'production') {
        console.log('--------------------NEW Expires Date/Time: ', action.payload);
      }
      if (action.payload) {
        const splittedetDate = action.payload.split('/')
        const yearAndTime = splittedetDate[2].split(' ')
        const splittedTime = yearAndTime[1].split(':')
        const year = yearAndTime[0]
        const month = splittedetDate[0]
        const day = splittedetDate[1]
        const hour = splittedTime[0]
        const min = splittedTime[1]
        const milliseconds = Date.parse(`${year}-${month}-${day}T${hour}:${min}:00.000Z`);
        state.authorizationExpiresTimeout = milliseconds;
        setAuthorizationExpiresTimeoutLocally(milliseconds);
      }
    },
    toggleIsSideMenuPinned: (state) => {
      const newIsSideMenuPinned = !state.isSideMenuPinned;

      setIsSideMenuPinned(newIsSideMenuPinned);
      state.isSideMenuPinned = newIsSideMenuPinned;
    },
    removeAuthorizationExpiresTimeout: (state) => {
      clearAuthorizationExpiresTimeoutLocally();
      state.authorizationExpiresTimeout = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(logout.fulfilled, (state, action) => {
        state.authorizationExpiresTimeout = null;
      })
      .addCase(fetchDisabledFeatures.pending, (state) => {
        state.status = REQUEST_STATUS.loading;
      })
      .addCase(fetchDisabledFeatures.fulfilled, (state, action) => {
        if (action.payload.ok) {
          state.status = REQUEST_STATUS.succeeded;
          state.disabledFeatures = action.payload.data.data;
        } else {
          state.status = REQUEST_STATUS.failed;
          state.error = action?.payload?.error?.message;
        }
      });
  },
});

export const {
  getAndSetIsSideMenuPinned,
  toggleIsSideMenuPinned,
  checkAuthorizationExpiresTimeout,
  setAuthorizationExpiresTimeout,
  changeIsTabActive,
  removeAuthorizationExpiresTimeout,
} = appSettingsSlice.actions;

export default appSettingsSlice.reducer;
