import React, { useEffect, useState } from 'react';
import {
  Checkbox, Grid,
  makeStyles,
  MenuItem,
  Tab,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { TabContext, TabList, TabPanel, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import AllOptions from './AllOptions';
import { useSelector } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: '0 6px',
    fontSize: 12,
  },
  searchInput: {
    width: '100%',
    height: 50,
  },
  search: {},
  mainContainer: {
    width: '100%',
    height: 36,
  },
}));

const inputStyle = {
  height: '36px',
  lineHeight: '14px',
  fontSize: '14px',
};

const Search = ({
                  searchValue,
                  setSearchValue,
                }) => {
  const classes = useStyles();
  const { en: { titles } } = useSelector((state) => state.i18n);

  return <div className={classes.mainContainer}>
    <Typography className={classes.title}>
      {titles.search}
    </Typography>
    <TextField
      size={'small'}
      className={classes.searchInput}
      label='Search'
      variant={'outlined'}
      autoFocus={true}
      style={inputStyle}
      InputLabelProps={{ style: inputStyle }}
      inputProps={{ style: { ...inputStyle, padding: '0 14px' } }}
      classes={{
        root: classes.search,
        input: classes.search,
      }}
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
    />
  </div>;
};

export default Search;
