import React, { useRef } from 'react';
import {
  makeStyles,
  Grid,
  FormLabel,
  IconButton,
} from '@material-ui/core';
import cx from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import TablePlaceholder from '../../../Shared/TablePlaceholder';
import ordersPlaceholderImage from '../../../../assets/placeholders/orders.svg';
import HelperText from '../../../Shared/HelperText';
import CptInfoRow from './CptInfoRow';
import { BILLING_CLAIMS_STATUSES } from '../../constants';
import { getTransitionWithVariable } from '../../../../redux_store/reducer/reducers/i18nReducer';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  addNewButtonContainer: {
    position: 'relative',
    padding: '0 !important',
  },
  activitiesError: {
    position: 'absolute',
    color: theme.palette.error.main,
    bottom: 0,
  },
  tableContainer: {
    minHeight: 225,
    borderRadius: 10,
    border: '1px solid rgba(0, 0, 0, 0.37);',
    padding: '24px 6px !important',
    paddingBottom: '34px !important',
    margin: '32px 12px',
    boxShadow: '0px -61px 36px -51px rgba(255,255,255,0.92) inset',
    overflowX: 'auto',
  },
  disabledTableContainer: {
    paddingBottom: '0 !important',
  },
  errors: {
    borderColor: `${theme.palette.error.main} !important`,
    position: 'relative',
  },
  labelContainer: {
    height: 0,
    maxHeight: 0,
    position: 'relative',
    padding: '0px !important',
  },
  label: {
    position: 'absolute',
    top: 16,
    backgroundColor: 'white',
    left: 0,
    padding: '0 15px',
    margin: 0,
  },
  addNewButton: {
    position: 'absolute',
    bottom: 44,
    right: 24,
    boxShadow: '1px 4px 15px -5px rgba(0, 0, 0, 0.97)',
    border: '1px solid white',
    backgroundColor: theme.colors.green,

    '&:hover': {
      backgroundColor: theme.colors.green,
      opacity: 0.8,
    },
  },
  addNewButtonIcon: {
    color: 'white',
  },
}));

const CptInfo = ({
                   activities,
                   cptCodes,
                   modifiers,
                   backendErrors,
                   onDismiss,
                   updateCPTErrorsIfNeeded,
                   updateClaimValue,
                   disabled,
                   appointmentDate,
                   cptStatus,
                   billingStatus,
                   navWidth,
                 }) => {
  const classes = useStyles();
  const {
    en: {
      placeholders,
      forms,
    },
  } = useSelector((state) => state.i18n);
  const myRef = useRef(null);

  const addItem = async ({
                           code,
                           modifier,
                           icd,
                           dos,
                         }) => {
    updateClaimValue('activities', [
      ...(activities || []),
      {
        id: Math.random(),
        code: code ?? null,
        modifier: modifier ?? null,
        icd: icd ?? null,
        allowed: '0.00',
        paid: '0.00',
        pr: '0.00',
        prType: null,
        dos: dos ?? null,
      },
    ]);
  };

  return (
    <>
      <Grid item xs={12} className={classes.labelContainer}>
        <FormLabel className={classes.label}>{forms.cptCode}</FormLabel>
      </Grid>
      <Grid
        item
        xs={12}
        className={cx(classes.tableContainer, {
          [classes.disabledTableContainer]: disabled,
          [classes.errors]: typeof backendErrors.activities === 'string',
        })}
      >
        {!activities ||
        (activities.length === 0 && (
          <TablePlaceholder
            image={ordersPlaceholderImage}
            itemsNotFoundText={placeholders.noCPTCodes}
            message={getTransitionWithVariable(placeholders.addCPTCodes, {
              item: '+',
            })}
          />
        ))}
        <div>
          {(activities ?? []).map((cpt, index) => {
            return <CptInfoRow
              key={cpt.id}
              cpt={cpt}
              index={index}
              disabled={disabled || cpt.isClaimReported}
              updateCPTErrorsIfNeeded={updateCPTErrorsIfNeeded}
              updateClaimValue={updateClaimValue}
              onDismiss={onDismiss}
              backendErrors={backendErrors}
              cptCodes={cptCodes}
              modifiers={modifiers}
              activities={activities}
              appointmentDate={appointmentDate}
              cptStatus={cptStatus}
              billingStatus={billingStatus}
              navWidth={navWidth}
            />;
          })}
          <div ref={myRef} id='myDiv' />
        </div>
        <HelperText className={cx(classes.activitiesError)} style={{ marginLeft: 15 }}>
          {typeof backendErrors.activities === 'string' && backendErrors.activities}
        </HelperText>
      </Grid>
      {!disabled && billingStatus !== BILLING_CLAIMS_STATUSES.appointmentCancelled
      && billingStatus !== BILLING_CLAIMS_STATUSES.insuranceIssue && (
        <Grid item xs={12} sm={12} className={classes.addNewButtonContainer}>
          <IconButton onClick={addItem} className={classes.addNewButton}>
            <AddIcon color='secondary' className={classes.addNewButtonIcon} />
          </IconButton>
        </Grid>
      )}
    </>
  );
};

export default CptInfo;
