import React, { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { camelCase } from 'lodash';
import TablePlaceholder from 'components/Shared/TablePlaceholder';
import VirtualizedTable from 'components/Table/VirtualizedTable';
import { AppRouteContext } from 'contexts';
import {
  ASSESSMENT_STATUSES,
  ASSESSMENT_STATUSES_OPTIONS,
  FAKE_ASSESSMENT,
  GET_ASSESSMENTS_COLUMNS,
  INVITATION_TYPE,
} from 'components/Assessments/constants';
import { getLocalizedLabel } from 'helpers/localize';
import assessmentsPlaceholderImage from 'assets/placeholders/orders.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PendingIcon } from 'assets/icons/pending.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info_gray.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/file_download_black.svg';
import { WARNING_DURATION } from '../../../../contexts/NotificationsProvider';
import { routes } from '../../../../config/routes';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../../../redux_store/reducer/reducers/notificationsReducer';
import getFakeDataList from '../../../Shared/FakeDataGenerator';
import { TABLE_KEYS } from '../../../../redux_store/reducer/reducers/tableColumnsReducer';
import showServerError from '../../../../helpers/showError';
import { useMutation } from 'react-fetching-library';
import { checkPermission } from '../../../../helpers/checkPermissions';
import permissions from '../../../../config/permissions';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { getTransitionWithVariable } from '../../../../redux_store/reducer/reducers/i18nReducer';
import { downloadFile } from '../../../../helpers/DownloadPDF';

const AssessmentsTable = ({
                            modifiedAssessments,
                            dataLoading,
                            toggleConfirmResentDialog,
                            reloadData,
                            totalCount,
                            FetchProps,
                            totalAccountsCount,
                            setEnableClaimModalInfo,
                            setAssessmentLinkForShareModal,
                          }) => {
  const {
    en: {
      titles,
      placeholders,
      errors,
      notifications,
    },
  } = useSelector((state) => state.i18n);
  const {
    patientUuid: paramsPatientUuid,
    accountUuid: paramAccountUuid,
  } = useParams();
  const isPatientPage = Boolean(paramsPatientUuid);
  const dispatch = useDispatch();
  const { metadata: { currentAccountPermissions } } = useSelector((state) => state.user);
  const { setAppRoute } = useContext(AppRouteContext);
  const hasLoadFilesPermission = checkPermission(
    currentAccountPermissions,
    permissions.SURVEYS_GET,
  );
  const isDistributionSponsor = checkPermission(
    currentAccountPermissions,
    permissions.ACCOUNTS_GET,
  );
  const [isDataChangingRequesting, setIsDataChangingRequesting] = useState(false);

  const redirectToAssessmentReport = (props) => {
    const { uuid, accountUUID, patientUUID, surveyName, status } = props;
    setAppRoute({
      path: routes.patientAssessmentReport.path,
      params: {
        accountUuid: accountUUID,
        patientUuid: patientUUID,
        assessmentUuid: uuid,
        status: camelCase(status),
      },
      title: `${surveyName} ${titles.report}`,
    });
  };

  const handleRowClick = (assessment) => {
    const status = camelCase(assessment.status);
    if (
      status === ASSESSMENT_STATUSES.completed ||
      status === ASSESSMENT_STATUSES.inProgress ||
      status === ASSESSMENT_STATUSES.eTransfer ||
      status === ASSESSMENT_STATUSES.pendingReview
    ) {
      redirectToAssessmentReport(assessment);
    } else {
      dispatch(
        enqueueSnackbar({
          message: notifications.assessmentMustBeCompleted,
          options: {
            variant: SNACKBAR_VARIANTS.warning,
            autoHideDuration: WARNING_DURATION,
          },
        }),
      );
    }
  };

  const checkIsDisabled = (assessmentUuid) => {
    if (dataLoading || !modifiedAssessments) {
      return true;
    }
    let isDisabled = false;

    modifiedAssessments.forEach((assessment) => {
      if (assessment.uuid === assessmentUuid) {
        const allowed = assessment.claimable && assessment.status === getLocalizedLabel(
          ASSESSMENT_STATUSES_OPTIONS,
          ASSESSMENT_STATUSES.completed,
        );
        isDisabled = !allowed;
      }
    });
    return isDisabled;
  };

  const checkIsResendingDisabled = (assessmentUuid) => {
    if (dataLoading || !modifiedAssessments) {
      return true;
    }
    let isDisabled = false;

    modifiedAssessments.forEach((assessment) => {
      if (assessment.uuid === assessmentUuid) {
        isDisabled = assessment.invitationType === INVITATION_TYPE.notAllowed;
      }
    });

    return isDisabled;
  };

  const checkIsDownloadProgramPDFDisabled = (assessmentUuid) => {
    if (dataLoading || !modifiedAssessments || !hasLoadFilesPermission) {
      return true;
    }
    let isDisabled = true;

    modifiedAssessments.forEach((assessment) => {
      if (assessment.uuid === assessmentUuid && assessment.status === getLocalizedLabel(
        ASSESSMENT_STATUSES_OPTIONS,
        ASSESSMENT_STATUSES.completed,
      )) {
        isDisabled = false;
      }
    });

    return isDisabled;
  };

  const handleConfirmResentDialog = (e, props) => {
    toggleConfirmResentDialog({
      ...props,
      survey: props.uuid,
    });
  };

  const isTableDataLoading = dataLoading || !modifiedAssessments;
  const FAKE_ASSESSMENT_LIST = getFakeDataList(FAKE_ASSESSMENT);

  const importFile = (assessment) => ({
    method: 'GET',
    endpoint: `/accounts/${assessment.accountUUID}/surveys/${assessment.uuid}/program-report/pdf`,
    responseType: 'blob',
  });

  const { mutate: importMutate } = useMutation(importFile);

  const downloadProgramPDF = async (e, assessment) => {
    const { payload, error, status } = await importMutate(assessment);
    if (!error) {
      downloadFile(
        new Blob([payload]),
        `${assessment.surveyName} - ${assessment.patientLastName} ${assessment.patientFirstName} ${assessment.orderDate}.pdf`);
      dispatch(
        enqueueSnackbar({
          message: notifications.pdfSucceededSaved,
          options: {
            variant: SNACKBAR_VARIANTS.success,
          },
        }),
      );
    } else {
      switch (status) {
        case 422: {
          const obj = JSON.parse(await payload.text());
          Object.keys(obj.validationErrors).forEach(key =>
            showServerError(dispatch, obj.validationErrors[key]));
          break;
        }
        default: {
          showServerError(dispatch, errors.unknownError);
          break;
        }
      }
    }
  };

  const changeNoShowStatus = (assessment, urlEnd) => ({
    method: 'POST',
    endpoint: `/accounts/${assessment.accountUUID}/surveys/${assessment.uuid}/${urlEnd}`,
  });

  const { mutate: changeNoShow } = useMutation(changeNoShowStatus);

  const noShow = async (e, assessment) => {
    setIsDataChangingRequesting(true);
    const {
      payload,
      error,
      status,
    } = await changeNoShow(assessment, assessment.status === 'No Show' ? 'undo-no-show' : 'set-no-show');
    if (!error) {
      reloadData({ delay: 500 });
      dispatch(
        enqueueSnackbar({
          message: assessment.status === ASSESSMENT_STATUSES.noShow
            ? notifications.noShowStatusSucceededReversed
            : notifications.noShowStatusSucceededChanged,
          options: {
            variant: SNACKBAR_VARIANTS.success,
          },
        }),
      );
    } else {
      switch (status) {
        case 422: {
          Object.keys(payload.validationErrors).forEach(key =>
            showServerError(dispatch, payload.validationErrors[key]));
          break;
        }
        default: {
          showServerError(dispatch, errors.unknownError);
          break;
        }
      }
    }
    setTimeout(() => {
      setIsDataChangingRequesting(false);
    }, 500);
  };

  const checkIsNoShowDisabled = (assessmentUuid) => {
    if (dataLoading || !modifiedAssessments) {
      return true;
    }
    let isDisabled = false;

    modifiedAssessments.forEach((assessment) => {
      if (assessment.uuid === assessmentUuid) {
        const appointmentDate = new Date(assessment.appointmentTimestamp);
        const today = new Date();
        const allowed = assessment.appointmentTimestamp && appointmentDate <= today || assessment.status === ASSESSMENT_STATUSES.noShow;
        isDisabled = !allowed;
      }
    });
    return isDisabled;
  };


  const columns = useMemo(
    () => GET_ASSESSMENTS_COLUMNS(
      isPatientPage,
      paramAccountUuid,
      totalAccountsCount,
      isDistributionSponsor,
    ),
    [totalAccountsCount, isPatientPage, paramAccountUuid, isDistributionSponsor],
  );

  return <VirtualizedTable
    tableKey={paramAccountUuid ? TABLE_KEYS.assessmentsDS : TABLE_KEYS.assessments}
    hasColumnsSelect={true}
    fakeRows={FAKE_ASSESSMENT_LIST}
    rowCount={totalCount}
    rows={modifiedAssessments}
    columns={columns}
    onRowClick={handleRowClick}
    FetchProps={FetchProps}
    onDataLoading={isTableDataLoading || isDataChangingRequesting}
    currentAccountPermissions={currentAccountPermissions}
    actionProps={{
      actions: [
        {
          key: 'resendInvitationAction',
          icon: PendingIcon,
          onActionClick: handleConfirmResentDialog,
          children: titles.resendInvitation,
          isDisabled: checkIsResendingDisabled,
        },
        {
          key: 'claimableAction',
          icon: EditIcon,
          onActionClick: (e, claim) => {
            setEnableClaimModalInfo(claim);
          },
          children: titles.generateClaim,
          isDisabled: checkIsDisabled,
        },
        {
          key: 'viewAssessmentLink',
          icon: InfoIcon,
          onActionClick: (e, claim) => {
            setAssessmentLinkForShareModal(claim.url);
          },
          children: titles.viewAssessmentLink,
        },
        {
          key: 'downloadProgram',
          icon: DownloadIcon,
          onActionClick: downloadProgramPDF,
          children: titles.downloadProgram,
          isDisabled: checkIsDownloadProgramPDFDisabled,
        },
        {
          key: 'No Show',
          icon: VisibilityOffIcon,
          onActionClick: noShow,
          children: titles.noShow,
          isDisabled: checkIsNoShowDisabled,
          showConditionFlag: 'canRevertNoShowStatus',
          showConditionValue: false,
        },
        {
          key: 'Reverse No Show',
          icon: VisibilityIcon,
          onActionClick: noShow,
          children: titles.reverseNoShow,
          showConditionFlag: 'canRevertNoShowStatus',
          showConditionValue: true,
        },
        // red line
        // filter
      ],
    }}
    placeholder={
      <TablePlaceholder
        image={assessmentsPlaceholderImage}
        itemsNotFoundText={getTransitionWithVariable(placeholders.noItemsFound, {
          item: titles.assessments,
        })}
        message={getTransitionWithVariable(placeholders.reviseFiltersOrCreateYourFirstItem, {
          item: titles.assessments,
        })}
      />
    }
  />;
};

export default AssessmentsTable;
