import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import UserProvider from 'contexts/UserProvider';
import AppRouteProvider from 'contexts/AppRouteProvider';
import ActivePatientProvider from 'contexts/ActivePatientProvider';
import PublicRoute from 'components/Shared/PublicRoute';
import ProtectedRoute from 'components/Shared/ProtectedRoute';
import EnvTopBar from 'components/Shared/EnvTopBar';
import Idle, { getDiffWithUTCFromApi, getUTCNow } from 'components/Shared/Idle';
import { routes } from 'config/routes';
import logoSvg from 'assets/icons/silver-logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import CacheBuster from '../../CacheBuster';
import {
  checkAuthorizationExpiresTimeout,
  fetchDisabledFeatures,
  getAndSetIsSideMenuPinned,
} from '../../redux_store/reducer/reducers/appSettingsReducer';
import useNotifier from '../Notifications/useNotifier';
import { getUser } from '../../redux_store/reducer/reducers/userReducer/api';
import { REQUEST_STATUS } from '../../redux_store/constants';
import {
  changeCurrentAccountForAlertsNotifications,
  startAlertsConnection,
} from '../../redux_store/reducer/middleware/alertsMiddleware';
import { getIsLogin } from '../../redux_store/reducer/reducers/userReducer/userReducer';
import { AppRouteContext } from '../../contexts';

const useStyles = makeStyles((theme) => ({
  main: {
    height: '100vh',
    position: 'relative',
    backgroundImage: `url(${logoSvg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '60%',
    backgroundPosition: '115% 20%',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: theme.colors.gray3,
      zIndex: -1,
    },
  },
}));

const ROUTES = Object.values(routes);

const App = () => {
  useNotifier();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isLogin, status, user } = useSelector((state) => state.user);
  const { metadata } = useSelector((state) => state.user);
  const { setAppRoute } = useContext(AppRouteContext);
  const [isDialogOpen, setDialogOpen] = useState(false);
  useEffect(() => {
    dispatch(getAndSetIsSideMenuPinned());
    dispatch(fetchDisabledFeatures());
    dispatch(getIsLogin());
    dispatch(checkAuthorizationExpiresTimeout());
    if (isLogin) {
      dispatch(getUser());
    }
    getDiffWithUTCFromApi();
  }, []);

  useEffect(() => {
    if (isLogin && !user) {
      dispatch(getUser());
      startAlertsConnection();
    }
  }, [isLogin]);

  useEffect(() => {
    if (metadata?.currentAccount?.uuid) {
      dispatch(changeCurrentAccountForAlertsNotifications(metadata?.currentAccount?.uuid));
    }
  }, [metadata?.currentAccount?.uuid]);

  useEffect(() => {
    if (status === REQUEST_STATUS.failed) {
      setAppRoute({
        path: routes.login.path,
      });
    }
  }, [status]);

  return (
    <AppRouteProvider>
      <UserProvider>
        <CacheBuster>
          <ActivePatientProvider>
            <Idle isDialogOpen={isDialogOpen} setDialogOpen={setDialogOpen}/>
            <main className={classes.main}>
              <EnvTopBar />
              <Routes>
                {ROUTES.map((route) => {
                  const { additional } = route;
                  return additional.protected ? (
                    <Route
                      exact
                      path={route.path}
                      element={
                        <ProtectedRoute isDialogOpen={isDialogOpen} key={route.path || ''} {...route} />
                      }
                    />
                  ) : (
                    <Route
                      exact
                      path={route.path}
                      element={
                        <PublicRoute key={route.path || ''} {...route} />
                      }
                    />
                  );
                })}
              </Routes>
            </main>
          </ActivePatientProvider>
        </CacheBuster>
      </UserProvider>
    </AppRouteProvider>
  );
};

export default App;
