import React from 'react';
import { makeStyles, Chip, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { currencyFormat } from './helpers';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chipContainer: {
    marginTop: 16,
    marginRight: 8,
    paddingRight: 12,
    display: 'flex',
    border: `1px solid ${theme.colors.gray1}`,
    borderRadius: 6,
    fontSize: '.8rem',
    fontWeight: 500,
    color: theme.palette.primary.main,
    alignItems: 'center',
  },
  chipTitle: {
    borderRight: `1px solid ${theme.colors.gray1}`,
    padding: '6px 12px',
    marginRight: 12,
    color: theme.colors.blue2,
  },
}));

const TableChips = ({
                      aggregations,
                    }) => {
  const classes = useStyles();
  const { en: { tables } } = useSelector((state) => state.i18n);

  const getFormattedValue = (value, isItAmount, isRounded) => {
    if (isItAmount) {
      return currencyFormat(value);
    } else if (isRounded) {
      return Math.round(value * 100) / 100;
    } else {
      return value;
    }
  };

  return !!aggregations ? <div className={classes.container}>
    {
      Object.keys(aggregations)
        .filter((aggregation) => aggregation !== 'TotalCount' && aggregation !== 'AccountsCount')
        .map((aggregation_key) => {
            const isItAmount = aggregation_key?.endsWith('Amount');
            const isRounded = aggregation_key?.endsWith('EngageRate');
            const value = aggregations[aggregation_key];
            return <div className={classes.chipContainer}>
              <Typography className={classes.chipTitle}>
                {tables[aggregation_key]}
              </Typography>
              <Typography>
                {getFormattedValue(value, isItAmount, isRounded)}
              </Typography>
            </div>;
          },
        )
    }
  </div> : <></>;
};

export default TableChips;
