import React, { useState, useMemo, useEffect } from 'react';
import { generatePath, useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import qs from 'qs';
import { AppRouteContext } from 'contexts';
import { QUERY_DELAY, DEBOUNCE_DELAY } from 'components/Shared/constants';
import { useSelector } from 'react-redux';

const getParsedBc = (search) => {
  const parsedSearch =
    search &&
    qs.parse(search, {
      ignoreQueryPrefix: true,
    });
  return parsedSearch && parsedSearch.bc
    ? JSON.parse(atob(parsedSearch.bc))
    : [];
};
const getLastRoute = (search) => {
  const parsedBc = getParsedBc(search);
  return parsedBc.length ? parsedBc[parsedBc.length - 1] : null;
};

let metadata_store = null;

const AppRouteProvider = ({ children }) => {
  /*
    The idea is to store breadcrumbs params in URL query string.
    It helps to restore breadcrumbs after reloading the page.
    The query string is encoded by Base64.
    App route schema:
    {
      path: String,
      params: Object,
      title: String,
      removeQueryString: Boolean,
      clearBreadcrumbs: Boolean,
    }
  */
  const navigate = useNavigate();
  const { search } = useLocation();
  /*
    state is been initialized immediately if URL includes query string
    or later from ProtectedRoute call
  */
  const [bc, setBc] = useState(getParsedBc(search));
  const [appRoute, changeAppRoute] = useState(getLastRoute(search));

  const { metadata } = useSelector((state) => state.user);

  const getBc = (newItem) => {
    // clear an array and set only first item
    // case: sidebar items click, not-found route
    if (newItem.clearBreadcrumbs || !newItem.path) {
      return [newItem];
    }
    // find the same route in breadcrumbs
    // case: click on breadcrumb in navigation
    const index = bc.indexOf(bc.find((item) => item.path === newItem.path));
    return index === -1 ? [...bc, newItem] : [...bc].slice(0, index + 1);
  };

  const eventListener = () => {
    /*
      handle changing route outside the dashboard
      case: browser back button click
    */
    setBc(getParsedBc(document.location.search, ''));
    changeAppRoute(getLastRoute(search));
  };

  const updateBc = (newAppRoute) => {
    const newBc = getBc(newAppRoute);
    setBc(newBc);
    return newBc;
  };

  useEffect(() => {
    window.addEventListener('popstate', eventListener, true);
    return () => {
      window.removeEventListener('popstate', eventListener);
    };
  }, []);

  useEffect(() => {
    metadata_store = metadata;
  }, [metadata]);

  const updateRoute = (newAppRoute, options = {}) => {
    changeAppRoute(newAppRoute);
    const newBc = updateBc(newAppRoute);

    // skip initial redirect
    if (appRoute) {
      const path = generatePath(newAppRoute.path, newAppRoute.params);
      const search = !newAppRoute.removeQueryString &&
        qs.stringify({
          bc: btoa(JSON.stringify(newBc)),
        });

      navigate(path, {
        search: search,
        state: {
          delay: options.addDelay ? QUERY_DELAY : 0,
        },
      });

      let history = JSON.parse(window.localStorage.getItem('history')) || [];

      const prewRoute = history.length ? history[history.length - 1] : null;
      const isPathesDiff = prewRoute && (prewRoute.route.path !== path) && (generatePath(prewRoute.route.path, prewRoute.route.params));
      const isAccDiff = prewRoute ? history[history.length - 1].currentAccountUuid !== (options.currentAccountUuid ?? metadata_store?.currentAccount?.uuid) : true;

      if (history.length < 1 || isPathesDiff || isAccDiff) {
        history = [...history, {
          route: newAppRoute,
          currentAccountUuid: options.currentAccountUuid ?? metadata_store?.currentAccount?.uuid,
        }];
      }

      if (history.length > 10) {
        history = history.slice(-10);
      }

      localStorage.setItem('history', JSON.stringify(history));
    }
  };

  const setAppRoute = (newRoute, ...rest) => {
    updateRoute(newRoute, ...rest);
  };

  const value = useMemo(
    () => ({
      setAppRoute: debounce(setAppRoute, DEBOUNCE_DELAY),
      breadcrumbs: bc,
    }),
    [bc],
  );
  return (
    <AppRouteContext.Provider value={value}>
      {children}
    </AppRouteContext.Provider>
  );
};

AppRouteProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AppRouteProvider;
