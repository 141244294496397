import i18next from 'i18next';
import FILTER_TYPES from '../Filters/constants';
import { CUSTOM_TAGS_OPTIONS } from '../Shared/CustomTags';

export const ACCOUNT_TYPES = {
  STANDARD: 'standard',
  DISTRIBUTION_SPONSOR: 'distributionSponsor',
};

export const STATES = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
  { label: 'District of Columbia', value: 'DC' },
];

export const CLAIM_REPORT_TYPES = [
  { label: 'Without financial information', value: 'templateA' },
  { label: 'With financial information', value: 'templateB' },
];

export const FAKE_ACCOUNT = {
  customTags: [],
  uuid: '',
  businessName: '',
  customIdentifier: '',
  address: '',
  moreTitle: '',
};

export const ACCOUNT_FIELDS = ['customTags', 'businessName', 'customIdentifier', 'address'];

export const ACCOUNT_COLUMNS = [
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:customTags'),
    dataKey: ACCOUNT_FIELDS[0],
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: CUSTOM_TAGS_OPTIONS,
    },
  },
  {
    width: 300,
    label: i18next.t('tables:name'),
    dataKey: ACCOUNT_FIELDS[1],
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT_WITH_DYNAMIC_HINT,
    },
    flexGrow: 2,
  },
  {
    width: 120,
    label: i18next.t('tables:customId'),
    dataKey: ACCOUNT_FIELDS[2],
    columnData: {
      isFilterable: true,
    },
    requiredColumn: true,
  },
  {
    width: 300,
    label: i18next.t('tables:address'),
    dataKey: ACCOUNT_FIELDS[3],
    columnData: {
      isFilterable: true,
    },
    flexGrow: 5,
  },
];
