import React, { useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { CSV_FILENAME, CSV_HEADERS } from '../../../constants';

const ExportCSVButtonLink = ({
                               CSVData,
                               exporting,
                               setExporting,
                               downloadCSVLink,
                             }) => {

  useEffect(() => {
    if (
      CSVData &&
      exporting &&
      downloadCSVLink.current &&
      downloadCSVLink.current.link
    ) {
      setExporting(false);
      setTimeout(() => {
        downloadCSVLink.current.link.click();
      });
    }
  }, [CSVData]);

  return CSVData && (
    <CSVLink
      data={CSVData}
      headers={CSV_HEADERS}
      filename={CSV_FILENAME}
      ref={downloadCSVLink}
      target='_blank'
    />
  );
};

export default ExportCSVButtonLink;
