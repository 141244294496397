import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormHelperText, FormLabel, makeStyles } from '@material-ui/core';
import showServerError from 'helpers/showError';
import { getFormattedDate } from 'helpers/localize';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-fetching-library';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../../../redux_store/reducer/reducers/notificationsReducer';
import CalendarPopover from '../../../FormikFields/Calendar/CalendarPopover';
import RoundedButton from '../../../Shared/RoundedButton';
import Modal from '../../../Shared/Modal';
import { downloadFile } from '../../../../helpers/DownloadPDF';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalRow: {
    width: 'calc(100% - 24px)',
    margin: 12,
    padding: 0,
  },
  classesColumn: {
    width: '100%',
    margin: 'auto',
    maxWidth: 310,
  },
  datepickerWrapper: {
    padding: '5px 12px',
    borderRadius: 8,
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    width: '100%',
    minHeight: 40,
  },
  datepickerWrapperWithError: {
    border: `1px solid #dc1212`,
  },
  roleLabel: {
    marginTop: 20,
    margin: theme.spacing(0.5),
    color: 'rgba(0, 0, 0, 0.54)',
  },
  formError: {
    width: '100%',
    height: 20,
  },
  firstTitleStyle: {
    padding: '32px 30px 0px 30px',
  }
}));

const DownloadPracticeOptimizationReportModal = ({
                                                   closeDownloadPdfModal,
                                                   openDownloadPdfModal,
                                                   accountUUID,
                                                   accountName,
                                                 }) => {
  const {
    en: {
      notifications,
      titles,
      errors,
      btn,
    },
  } = useSelector((state) => state.i18n);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [pdfData, setPdfData] = useState('');
  const [dataValidationError, setDataValidationError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const closePdfModal = () => {
    setPdfData('');
    setDataValidationError(false);
    closeDownloadPdfModal();
  };

  const importFile = (values) => ({
    method: 'POST',
    endpoint: `/accounts/${accountUUID}/practice-optimization-report.pdf`,
    body: values,
    responseType: 'blob',
  });

  const { mutate: importMutate, loading: pdfLoading } = useMutation(importFile);

  const downloadPDF = async () => {
    const dataArr = pdfData.split(',').map((data) => {
      const dataParsedArr = data.split('/');
      return `${dataParsedArr[2]}-${dataParsedArr[0]}-${dataParsedArr[1]}`;
    });
    const config = {
      fromDate: dataArr[0],
      toDate: dataArr[1],
    };

    const { payload, error, status } = await importMutate(config);

    if (!error) {
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);
      downloadFile(
        new Blob([payload], { type: 'application/pdf' }),
        `${accountName ?? titles.practiceOptimizationReport} ${pdfData}.pdf`,
      );
      dispatch(
        enqueueSnackbar({
          message: notifications.pdfSucceededSaved,
          options: {
            variant: SNACKBAR_VARIANTS.success,
          },
        }),
      );
      closePdfModal();
    } else {
      setIsFailed(true);
      setTimeout(() => {
        setIsFailed(false);
      }, 2000);
      switch (status) {
        case 422:
        case 500: {
          showServerError(dispatch, errors.validationError);
          setDataValidationError(true);
          break;
        }
        default: {
          showServerError(dispatch, errors.unknownError);
          break;
        }
      }
    }
  };

  const handleDateChange = (newDate) => {
    setDataValidationError(false);
    if (newDate[0] == null) {
      setPdfData('');
    } else {
      const parsedVal = newDate.map((data) => getFormattedDate(data));
      setPdfData(parsedVal.join(','));
    }
  };


  let value;
  if (!pdfData) {
    value = null;
  } else {
    value = pdfData.split(',').map((data) => new Date(data));
  }

  return (
    <Modal
      open={openDownloadPdfModal}
      classes={{ paper: classes.modal }}
      title={titles.practiceOptimizationReport}
      handleClose={closePdfModal}
      disableBackdropClick={pdfLoading}
      firstTitleStyle={classes.firstTitleStyle}
    >
      <Box pt={5} p={4} className={classes.modalRow}>
        <Box
          display='flex'
          justifyContent='center'
          flexDirection='column'
          className={cx(classes.modalRow, classes.classesColumn)}
        >
          <FormLabel
            {...{
              className: classes.roleLabel,
            }}
          >
            {titles.selectRangeDate}
          </FormLabel>
          <div
            className={cx(classes.datepickerWrapper, {
              [classes.datepickerWrapperWithError]: dataValidationError,
            })}
          >
            <CalendarPopover
              width={130}
              shouldReduceFontSize={false}
              shouldRange
              onChange={handleDateChange}
              date={value}
            />
          </div>
          <FormHelperText error className={classes.formError}>
            {dataValidationError && notifications.dataIsInvalid}
          </FormHelperText>
        </Box>
        <Box
          display='flex'
          justifyContent='space-between'
          className={classes.modalRow}
        >
          <RoundedButton
            variant='outlined'
            color='primary'
            size='small'
            disabled={pdfLoading}
            onClick={closePdfModal}
            className={classes.createButton}
          >
            {btn.cancel}
          </RoundedButton>
          <RoundedButton
            variant='contained'
            color='primary'
            size='small'
            disabled={!value || pdfLoading}
            onClick={downloadPDF}
            isLoading={pdfLoading}
            isSuccess={isSuccess}
            isFailed={isFailed}
            className={classes.createButton}
          >
            {btn.Download}
          </RoundedButton>
        </Box>
      </Box>
    </Modal>
  );
};

DownloadPracticeOptimizationReportModal.propTypes = {
  accountUUID: PropTypes.string.isRequired,
  openDownloadPdfModal: PropTypes.bool.isRequired,
  closeDownloadPdfModal: PropTypes.func.isRequired,
};

export default DownloadPracticeOptimizationReportModal;
