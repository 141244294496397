import { createSlice } from '@reduxjs/toolkit';
import { getNewAlertsNotification, sendViewedAlertsUuids, changeAlertAcknowledgeStatusByUuids } from './api';
import { REQUEST_STATUS } from '../../../constants';

let init_alert_list = {
  'Imminent Risk': {
    'count': 0,
    'type': 'critical',
  },
  'Help Seeking Behavior': {
    'count': 0,
    'type': 'nonCritical',
  },
  'Risk Factors Identified': {
    'count': 0,
    'type': 'nonCritical',
  },
};

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState: {
    tablePreFilters: {},
    newAlertList: init_alert_list,
    totalAlertsCount: 0,
    status: REQUEST_STATUS.idle,
    statusSendingViewedAlertsUuids: REQUEST_STATUS.idle,
    statusChangeAcknowledged: REQUEST_STATUS.idle,
  },
  reducers: {
    setAlertsPreFilter: (state, action) => {
      state.tablePreFilters = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getNewAlertsNotification.pending, (state) => {
        state.status = REQUEST_STATUS.loading;
      })
      .addCase(getNewAlertsNotification.fulfilled, (state, action) => {
        if (action.payload.ok) {
          state.status = REQUEST_STATUS.succeeded;
          let newState = {
            'Imminent Risk': {
              'count': 0,
              'totalCount': 0,
              'type': 'critical',
            },
            'Help Seeking Behavior': {
              'count': 0,
              'totalCount': 0,
              'type': 'nonCritical',
            },
            'Risk Factors Identified': {
              'count': 0,
              'totalCount': 0,
              'type': 'nonCritical',
            },
          };
          action.payload.data.data.items.forEach(({ title, count, totalCount }) => {
            if(title === 'IMMINENT RISK') {
              newState['Imminent Risk'].count = count;
              newState['Imminent Risk'].totalCount = totalCount ?? count;
            } else {
              newState[title].count = count;
              newState[title].totalCount = totalCount ?? count;
            }
          });
          state.totalAlertsCount = action.payload.data.data.totalCount ?? 0;
          state.newAlertList = newState;
        } else {
          state.status = REQUEST_STATUS.failed;
        }
      })
      .addCase(sendViewedAlertsUuids.pending, (state) => {
        state.statusSendingViewedAlertsUuids = REQUEST_STATUS.loading;
      })
      .addCase(sendViewedAlertsUuids.fulfilled, (state, action) => {
        if (action.payload.ok) {
          state.statusSendingViewedAlertsUuids = REQUEST_STATUS.succeeded;
        } else {
          state.statusSendingViewedAlertsUuids = REQUEST_STATUS.failed;
        }
      })
      .addCase(changeAlertAcknowledgeStatusByUuids.pending, (state) => {
        state.statusChangeAcknowledged = REQUEST_STATUS.loading;
      })
      .addCase(changeAlertAcknowledgeStatusByUuids.fulfilled, (state, action) => {
        if (action.payload.ok) {
          state.statusChangeAcknowledged = REQUEST_STATUS.succeeded;
        } else {
          state.statusChangeAcknowledged = REQUEST_STATUS.failed;
        }
      });
  },
});

export const {
  setAlertsPreFilter,
} = alertsSlice.actions;

export default alertsSlice.reducer;
