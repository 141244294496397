import React, { useEffect, useMemo, useContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { ClientContext } from 'react-fetching-library';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import { useTranslation } from 'react-i18next';
import { UserContext, AppRouteContext } from 'contexts';
import showServerError from 'helpers/showError';
import { clearFilters } from 'config/sessionStorage';
import { findAllowedRoute } from 'helpers/checkPermissions';
import HipaaCheck from 'components/Shared/HipaaCheck';
import { useSelector, useDispatch } from 'react-redux';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../redux_store/reducer/reducers/notificationsReducer';
import {
  logout as logoutAction,
  getMetadata,
} from '../redux_store/reducer/reducers/userReducer/api';
import {
  setUser as setUserAction,
  setHipaaCheckParams as setHipaaCheckParamsAction,
  setMetadata as setMetadataAction,
  setBlockedAccounts,
} from '../redux_store/reducer/reducers/userReducer/userReducer';

let metadata_store = null;

const UserProvider = ({ children }) => {
  const { user, hipaaCheckParams, metadata, blockedAccounts } = useSelector(
    (state) => state.user,
  );
  const { disabledFeatures } = useSelector((state) => state.appSettings);
  const dispatch = useDispatch();
  const clientContext = useContext(ClientContext);
  const { setAppRoute, breadcrumbs } = useContext(AppRouteContext);
  const { t } = useTranslation(['notifications']);
  const userUuid = user ? user.uuid : null;
  const accountUuid = metadata && metadata.currentAccount ? metadata.currentAccount.uuid : null;
  const [changeAccountLoading, setChangeAccountLoading] = useState(false);

  const setMetadata = (newMetadata) => {
    if (!isEqual(metadata, newMetadata)) {
      dispatch(setMetadataAction(newMetadata));
    }
  };

  useEffect(() => {
    setChangeAccountLoading(false);
  }, [accountUuid]);

  useEffect(() => {
    metadata_store = metadata;
  }, [metadata]);

  // fetch metadata if user exist
  useEffect(() => {
    if (userUuid) {
      refreshMetadata();
    }
  }, [userUuid]);

  const refreshMetadata = () => {
    dispatch(getMetadata());
  };

  const checkHipaa = (newUser, newMetadata, newRoute) => {
    dispatch(
      setHipaaCheckParamsAction({
        newUser: newUser,
        newMetadata: newMetadata,
        newRoute: newRoute,
      }),
    );
  };

  const changeAccount = async (accountUuid, route) => {
    setChangeAccountLoading(true);
    const { payload, error } = await clientContext.query({
      method: 'POST',
      endpoint: `/auth/token/account/${accountUuid}`,
    });
    if (!error && payload) {
      const { user: newUser, authMetadata } = payload;
      if (!authMetadata) {
        showServerError(
          dispatch,
          'You do not have access to any accounts on the QHS dashboard. If you feel this is an error, contact the administrator for your QHS account.',
        );
        return;
      }
      // clear fetch filters (used in useFetchList)
      clearFilters();

      let newRoute;
      if (route) {
        newRoute = route;
      } else {
        // set top parent route
        const allowedRoute = findAllowedRoute(
          breadcrumbs[0],
          authMetadata.currentAccountPermissions,
          disabledFeatures,
        );
        newRoute = {
          path: allowedRoute.path,
          title: allowedRoute.additional.title,
          clearBreadcrumbs: true,
        };
      }
      checkHipaa(newUser, authMetadata, newRoute);
    } else {
      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: user.uuid,
      };
      showServerError(dispatch, '', options);
      setChangeAccountLoading(false);
    }
  };

  const eventListener = () => {
    /*
         handle changing route outside the dashboard
         case: browser back button click
       */
    const newHistory = [...(JSON.parse(window.localStorage.getItem('history')) || [])];
    let newRoute;
    if (newHistory.length > 2) {
      newHistory.pop();
      newRoute = newHistory[newHistory.length - 1];
      const accountUuid = metadata_store && metadata_store.currentAccount ? metadata_store.currentAccount.uuid : null;
      if (newRoute && newRoute.currentAccountUuid && (newRoute.currentAccountUuid !== accountUuid)) {
        newHistory.pop();
        localStorage.setItem('history', JSON.stringify(newHistory));
        setChangeAccountLoading(true);
        changeAccount(newRoute.currentAccountUuid, newRoute.route);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('popstate', eventListener, true);
    return () => {
      window.removeEventListener('popstate', eventListener);
    };
  }, []);

  const handleSuccessHipaaCheck = () => {
    const { newUser, newMetadata, newRoute } = hipaaCheckParams;
    dispatch(setUserAction(newUser));
    if (newMetadata === null || Object.keys(newMetadata).length === 0) {
      showServerError(
        dispatch,
        'You do not have access to any accounts on the QHS dashboard. If you feel this is an error, contact the administrator for your QHS account.',
      );
    }
    setMetadata(newMetadata);
    setAppRoute(newRoute, { currentAccountUuid: newMetadata.currentAccount.uuid });
    dispatch(setHipaaCheckParamsAction(null));
  };

  const handleFailHipaaCheck = () => {
    dispatch(logoutAction());
  };

  const setUser = (newUser) => {
    dispatch(setUserAction(newUser));
  };

  const handleSwitchHipaaAccount = useCallback(() => {
    const {
      newMetadata: { accounts, currentAccount },
    } = hipaaCheckParams;
    dispatch(setHipaaCheckParamsAction(null));

    const newBlockedAccounts = uniq([...blockedAccounts, currentAccount.uuid]);
    dispatch(setBlockedAccounts(newBlockedAccounts));
    const accountsToCheck = accounts.filter(
      (item) => !newBlockedAccounts.includes(item.uuid),
    );

    if (accountsToCheck.length) {
      changeAccount(accountsToCheck[0].uuid);
      dispatch(
        enqueueSnackbar({
          message: t('notifications:youHaveBeenSwitched', {
            name: accountsToCheck[0].businessName,
          }),
          options: {
            variant: SNACKBAR_VARIANTS.success,
          },
        }),
      );
    } else {
      dispatch(logoutAction());
    }
  }, [blockedAccounts, hipaaCheckParams]);

  // memoize a provider value
  const value = useMemo(
    () => ({
      changeAccount,
      checkHipaa,
      handleFailHipaaCheck,
      setUser,
      changeAccountLoading,
    }),
    [user, metadata, changeAccount, changeAccountLoading],
  );

  return (
    <UserContext.Provider value={value}>
      {children}
      {hipaaCheckParams && (
        <HipaaCheck
          user={hipaaCheckParams.newUser}
          metadata={hipaaCheckParams.newMetadata}
          onSuccess={handleSuccessHipaaCheck}
          onFail={handleFailHipaaCheck}
          onSwitchAccount={handleSwitchHipaaAccount}
        />
      )}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default UserProvider;
